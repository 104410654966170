import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Open } from "./assets/Open.svg";
import { ReactComponent as Close } from "./assets/Close.svg";
import { Breakpoints, primaryFont, textColour, white } from "./Constants";

const Container = styled.div`
  margin-bottom: 10px;
`;

const Banner = styled.div<{ open: boolean; inProgress: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  @media ${Breakpoints.Mobile} {
    padding-bottom: 10px;
  }
  background-color: ${white};
  ${(props) =>
    props.open &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
    `}
  cursor: ${(props) => (props.inProgress ? "not-allowed" : "pointer")};
`;

const Title = styled.div`
  color: ${textColour};
  font-family: ${primaryFont};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
`;

const StateIcon = styled.div``;

const ClosedContent = styled.div<{ show: boolean }>`
  ${(props) => !props.show && "height: 0"};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.25s;
`;

const Collapsing = styled.div<{ height?: number; open: boolean }>`
  ${(props) => props.height !== undefined && `height: ${props.height}px`};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: height 0.25s, opacity 0.25s;
  overflow: hidden;
  position: relative;
  ${(props) => !props.open && "pointer-events: none"};
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

interface IProps {
  title: React.ReactNode;
  open: boolean;
  inProgress: boolean;
  onOpen: () => void;
  onClose: () => void;
  closedContent: React.ReactNode;
  children: React.ReactNode | React.ReactNodeArray;
}

interface IState {
  openSize: number | undefined;
  closedSize: number | undefined;
}

class Collapsible extends React.Component<IProps, IState> {
  closedContentRef = React.createRef<HTMLDivElement>();
  contentRef = React.createRef<HTMLDivElement>();
  openObserver: ResizeObserver | undefined = undefined;
  closedObserver: ResizeObserver | undefined = undefined;

  state = {
    openSize: undefined,
    closedSize: undefined,
  };

  componentDidMount() {
    this.openObserver = new ResizeObserver((entries) => {
      this.setState({ openSize: entries[0].contentRect.height });
    });
    this.closedObserver = new ResizeObserver((entries) => {
      this.setState({ closedSize: entries[0].contentRect.height });
    });
    if (this.contentRef.current) {
      this.setState({
        openSize: this.contentRef.current.getBoundingClientRect().height,
      });
      this.openObserver.observe(this.contentRef.current);
    }
    if (this.closedContentRef.current) {
      this.setState({
        closedSize:
          this.closedContentRef.current.getBoundingClientRect().height,
      });
      this.closedObserver.observe(this.closedContentRef.current);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.closedContent && !prevProps.closedContent) {
      if (this.closedContentRef.current) {
        this.setState({
          closedSize:
            this.closedContentRef.current.getBoundingClientRect().height,
        });
        this.closedObserver?.observe(this.closedContentRef.current);
      }
    }
  }

  componentWillUnmount() {
    if (this.openObserver) {
      this.openObserver.disconnect();
    }
    if (this.closedObserver) {
      this.closedObserver.disconnect();
    }
  }

  render() {
    return (
      <Container onClick={this.props.open ? undefined : this.props.onOpen}>
        <Banner
          onClick={this.props.open ? this.props.onClose : this.props.onOpen}
          open={this.props.open}
          inProgress={this.props.inProgress}
        >
          <Title>{this.props.title}</Title>
          <StateIcon>{this.props.open ? <Close /> : <Open />}</StateIcon>
        </Banner>
        {this.props.closedContent && (
          <div style={{ height: 0 }}>
            <ClosedContent ref={this.closedContentRef} show={!this.props.open}>
              {this.props.closedContent}
            </ClosedContent>
          </div>
        )}
        <Collapsing
          open={this.props.open}
          height={
            this.props.open ? this.state.openSize : this.state.closedSize || 0
          }
        >
          <Contents ref={this.contentRef}>{this.props.children}</Contents>
        </Collapsing>
      </Container>
    );
  }
}

export default Collapsible;

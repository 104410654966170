import { ZLBoltMode } from "@zerolight-core/libzl"
import { ExteriorCamera, InteriorCamera } from "./Constants"

export type CameraType = "exterior" | "interior"

export type I18nString = {
	default?: string
	locales?: {key: string, value: string}[]
}

export type ConfigGroupOption = {
	id: string
	name: I18nString
	code?: string
	trim_code?: string
	trimline_code?: string
	description?: I18nString
	icon?: string
	compatibility?: {
		[groupid: string]: string[]
	}
	included?: {
		[groupid: string]: string[]
	}
	remove?: {
		[groupid: string]: string[]
	}
	animations: []
}

export type ConfigGroup = {
	id: string
	name: I18nString
	default?: string
	options: ConfigGroupOption[]
	mutuallyExclusive: boolean
	style: "text" | "mixed" | "icon"
	mode?: ZLBoltMode
	camera?: string
}

export type BeautyShot = {
	id: string
	name: I18nString
	icon: string
	cameraType?: CameraType
}

export type ReplaceTrim = {
	id: string
	replacements?: {find: string, replace: string}[]
}

export type Config = {
	manufacturer: string
	model: string
	year: string
	name: I18nString
	brochureName:I18nString	
	backgroundImage:string
	buildAndPriceCode:I18nString
	groups: ConfigGroup[]
	startingPrice: string
	beautyShots?: BeautyShot[]
	replaceTrims?: ReplaceTrim[]
	crmOffer: {
		micrositeCode: string,
		pdfCode: string,
		path: string,
		model: string,
	},
}

export function getCameraType(id: string, config: Config) : CameraType | undefined {
	if(config.beautyShots) {
		for(const camera of config.beautyShots) {
			if(id === camera.id) {
				return camera.cameraType;
			}
		}
	}
	if(id === ExteriorCamera) {
		return "exterior"
	}
	if(id.startsWith(InteriorCamera)) {
		return "interior"
	}
	return undefined
}

export function getI18nString(value: I18nString) : string {
	if(value.locales) {
		for(const locale of value.locales) {
			if(locale.key === window.zlurlparams.locale) {
				return locale.value;
			}
		}
	}
	return value.default || ""
}

export function getAnalyticsName(value: I18nString) : string {
	if(value.locales) {
		for(const locale of value.locales) {
			if(locale.key === "en-US") {
				return locale.value.toLowerCase();
			}
		}
	}
	return (value.default || "").toLowerCase()
}


const analyticsData : {[key: string]: any} = {
	event: "byoAction"
}

export function updateUnit(sku: string, segmentId: string, platformName: string, packageName: string, bundleName: string) {
	analyticsData.byoModel= {
		modelType: segmentId,
		modelName: platformName,
		modelSku: sku,
		packageName: packageName
	}
	analyticsData.interaction = {label: `${platformName}_${packageName}`}
	analyticsData.virtual = {bundle: bundleName.toLowerCase()}
}

export function analyticsMarker(name: [string] | [string, string] | [string, string, string], details?: any) {
	postMessage("zl_analytics", {name, details})
}

export function postMessage(type:string, data?:any) {
	console.log("ZL PostMessage: " + type, data);
	window.parent.postMessage({type, data}, "*")
}

export function cloudmine(data: {type: string[]}) {
	console.log("ZL Cloudmine: " + JSON.stringify(data))
	if(window.zlbolt.cloudmine) {
		window.zlbolt?.cloudmine?.event(data)
	}
	else {
		console.warn("Cloudmine not ready to receive events")
	}
}

export function localStorageGetItem(key: string) : string | null {
	try {
		if(window.localStorage) {
			return window.localStorage.getItem(key)
		}
	}
	catch {
		return null;
	}
	return null;
}

export function localStorageSetItem(key: string, value: string) {
	try {
		if(window.localStorage) {
			window.localStorage.setItem(key, value)
		}
	}
	catch {
	}
}

export function localStorageRemoveItem(key: string) {
	try {
		if(window.localStorage) {
			window.localStorage.removeItem(key)
		}
	}
	catch {
	}
}

window.addEventListener("message", (ev: MessageEvent) => {
	switch(ev.data.type) {
		case "cm_buildandprice": {
			cloudmine({type: ["CTA", "BuildAndPrice", "CM"]})
			break
		}
		case "cm_reserve": {
			if(ev.data.data.ctaName) {
				cloudmine({type: ["CTA", "Reserve", ev.data.data.ctaName]})
			}
			else {
				cloudmine({type: ["CTA", "Reserve", "CM"]})
			}
			break;
		}
	}
})


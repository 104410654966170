import styled from 'styled-components';
import { black, Breakpoints, lightGrey, red, white } from './Constants';
import { getIcon } from './Icons';

const Container = styled.button<{selected: boolean, trayOpen: boolean}>`
	& path {
		stroke: ${props => props.selected ? white : black};
	}
	background-color: ${props => props.selected ? red : props.trayOpen ? lightGrey : white};

	position: relative;
	appearance: none;
	outline: none;
	width: 50px;
	height: 50px;

	@media ${Breakpoints.Mobile} {
		width: 30px;
		height: 30px;
	}
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: auto;
`;


interface IProps {
	id: string
	icon: string
	name: string
	selected: boolean
	trayOpen: boolean
	onClick: () => void
}

export default function TrayButton(props: IProps) {
	return (
		<Container selected={props.selected} trayOpen={props.trayOpen} aria-label={props.name} data-automationid={props.id} onClick={props.onClick}>
			{getIcon(props.icon)}
		</Container>
	)
}

import { defineMessages } from "react-intl";

export default defineMessages({
	changeenvironment: {
		id: "changeenvironment",
		defaultMessage: "Change Environment"
	},
	changemode: {
		id: "changemode",
		defaultMessage: "Change Mode {toMode}"
	},
	done: {
		id: "done",
		defaultMessage: "BUILD & PRICE"
	},
	instructionsrotate: {
		id: "instructionsrotate",
		defaultMessage: "Tap and drag to rotate"
	},
	instructionszoom: {
		id: "instructionszoom",
		defaultMessage: "Pinch to zoom in and out"
	},
	instructionslandscape: {
		id: "instructionslandscape",
		defaultMessage: "Rotate to configure"
	},
	selecteditems: {
		id: "selecteditems",
		defaultMessage: "{selected} of {total} items"
	},
	skip: {
		id: "skip",
		defaultMessage: "OK"
	},
	viewexterior: {
		id: "viewexterior",
		defaultMessage: "View Exterior"
	},
	viewinterior: {
		id: "viewinterior",
		defaultMessage: "View Interior"
	},
	drivesequence: {
		id: "drivesequence",
		defaultMessage: "PLAY DRIVING MODE ▶"
	},
	stopsequence: {
		id: "stopsequence",
		defaultMessage: "Stop Video ◼"
	}
})
import React from "react";
import CloseImage from "./assets/icons/closeBtn.svg";
import styled from "styled-components";
import { primaryFont } from "./Constants";

export const Wrapper = styled(`div`)`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 62px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  right: 0;
  background: #fff;
`;
export const CloseBtn = styled(`a`)`
  position: absolute;
  top: 25px;
  right: 25px;
  display: inline-block;
`;
export const FormContainer = styled(`div`)`
  width: 100%;
  max-width: 850px;
  margin: auto;
`;
export const H6 = styled(`h6`)`
  font-family: ${primaryFont};
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  margin: 0;
  margin-bottom: 15px;
  margin-top: 15px;
  color: #777e86;
`;
export const H1 = styled(`h1`)`
  font-family: ${primaryFont};
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  margin: 0;
  color: #000000;
`;
export const CustomBtn = styled(`button`)`
  font-family: ${primaryFont};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  padding: 10px 40px;
  gap: 10px;

  width: auto;
  height: 50px;
  display: block;
  margin: auto;
  display: flex;
  align-items: center;
  background: #000000;
  color: #ffffff;
  transition: 200ms all ease-in-out;
  > div {
    position: relative;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    width: 30px;
    height: 30px;
  }
  &:hover {
    background: #da2e20;
  }
`;
interface IProps {
  setVisible: any;
}
export default function ThankyouMessage({ setVisible }: IProps) {
  return (
    <Wrapper>
      <CloseBtn
        href="#"
        onClick={(e) => {
          e.preventDefault();
          window.brochureFormOpen = false;
          setVisible(false);          
        }}
      >
        <img src={CloseImage} alt="" />
      </CloseBtn>
      <FormContainer id="thankyou">
        <H1>Thank you!</H1>
        <H1>Your personalized brochure is on its way.</H1>
        <H6>
          We will email you shortly with a link to your Personalized Interactive Brochure. If you checked Personalized PDF Download, it will be downloaded to your device directly.
        </H6>
        <CustomBtn id="requestButton" onClick={() => { window.brochureFormOpen = false; setVisible(false); }}>
          close
        </CustomBtn>
      </FormContainer>
    </Wrapper>
  );
}

import React from 'react';
import { SetClientID } from '@zerolight-core/zl-auth-handler';
import './zlurlparams';
import StringProvider from './Strings';
import PageRoot from './PageRoot';
import Layout from './Layout';
import { Global } from './Global';
import store from './Store';
import { Provider } from 'react-redux';

if(window.location.origin.startsWith("sitebuild-web-live.mitsubishi")) {
	SetClientID("MitsubishiWebLive")
}
else {
	SetClientID("MitsubishiWebDev")
}

const App: React.FC = () => {
	return (
		<Provider store={store}>
			<Global/>
			<StringProvider>
				<PageRoot>
					<Layout/>
				</PageRoot>
			</StringProvider>
		</Provider>
	);
}

export default App;

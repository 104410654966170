import styled, { CSSProperties } from "styled-components";
import { textColour, primaryFont, Breakpoints } from "./Constants";
import { getIcon } from "./Icons";

const Container = styled.div<{ inProgress: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: ${(props) => (props.inProgress ? "not-allowed" : "pointer")};

  & > img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
`;

const Label = styled.div<{ inProgress: boolean }>`
  color: ${(props) => (props.inProgress ? "#ccc" : textColour)};
  font-family: ${primaryFont};
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  @media ${Breakpoints.Mobile} {
    font-size: 21px;
  }
`;

interface IProps {
  name: string;
  inProgress: boolean;
  icon?: string;
  style?: CSSProperties;
}

export default function SelectedOption(props: IProps) {
  return (
    <Container inProgress={props.inProgress} style={props.style}>
      {props.icon && getIcon(props.icon, props.name)}
      <Label inProgress={props.inProgress}>{props.name}</Label>
    </Container>
  );
}


interface URLParams {
	locale: string
	env: string
	region: string
	debug: boolean
	localstream: string | null
	lazygenerate2d: boolean
	forcecachemiss: boolean
	streaming?: boolean,
	delay?: boolean,
	model: string
	year: string
	userenderchain: boolean
	prcode: string
	downloadpdf: boolean
	microsite: boolean
	timeout: any,
	scalingprofile:string | null,
	fullscreen?: boolean,
	cinematic?: boolean,
	flashbutton?: boolean,
	offerCode? : string | null,
	defaultEnvironment : string,
	config : string | null,
	css : string | null,
	modelselecturl : string | null,
	brochure : boolean
}

declare global {
	interface Window {zlurlparams: URLParams}
}

function getUrlParams(){
	const params = new URLSearchParams(window.location.search)

	window.zlurlparams = {
		locale: params.get("locale") || process.env.REACT_APP_DEFAULT_LOCALE || "en-US",
		env: params.get("env") || process.env.REACT_APP_DEFAULT_ENV || "live",
		region: params.get("region") || process.env.REACT_APP_DEFAULT_REGION || "us-east-2",
		debug: params.has("debug") && params.get("debug") !== "false",
		localstream: params.get("localstream"),
		forcecachemiss: params.has("forcecachemiss") && params.get("forcecachemiss") !== "false",
		lazygenerate2d: params.has("lazygenerate2d") && params.get("lazygenerate2d") !== "false",
		streaming: params.has("streaming") ? params.get("streaming") !== "false" : undefined,
		delay: params.has("delay") ? params.get("delay") !== "false" : undefined,
		model: params.get("model") || "outlander_dg",
		year: params.get("year") || "2024",
		userenderchain: params.get("userenderchain") === "true",
		prcode: params.has("prcode") ? params.get("prcode") ?? "" : "",
		downloadpdf: params.has("downloadpdf") && params.get("downloadpdf") !== "false",
		microsite: params.has("microsite") && params.get("microsite") !== "false",
		timeout: params.get("timeout") || 120000,
		scalingprofile: params.get("scalingprofile"),
		fullscreen: params.get("fullscreen") === "true",
		cinematic: params.has("cinematic") ? params.get("cinematic") !== "false" : true,
		flashbutton: params.has("flashbutton") ? params.get("flashbutton") !== "false" : true,
		offerCode: params.has("offerCode") ? params.get("offerCode") : undefined,
		defaultEnvironment: params.get("defaultEnvironment") ||  "cape_town_apartment",
		config: params.has("config") ? "_"+params.get("config") : "",
		css: params.has("css") ? "_"+params.get("css") : "",
		modelselecturl: params.has("modelselecturl") ? params.get("modelselecturl") : "",		
		brochure: params.get("brochure") === "true",
	}
}

getUrlParams()

export {}
import React from "react";
import styled from "styled-components";
import {  primaryFont } from './Constants';

export const Wrapper = styled(`div`)`
  font-family: ${primaryFont};  
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  right: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #000;
    font-weight: 500;
    font-size: 2em;
  }
`;

export default function Message() {
  return (
    <Wrapper>
      <span>Creating your PDF...</span>
    </Wrapper>
  );
}

import LibZL, { ZLBoltMode } from "@zerolight-core/libzl";
import { Authenticate, GetToken, LogOut } from "@zerolight-core/zl-auth-handler";
import {
  ExteriorCamera,
  InteriorCamera,
} from "./Constants";
import { cloudmine } from "./helpers";
import store, { Action } from "./Store";

declare global {
  interface Window {
    libZL: any;
    zlbolt: any;
    sequenceRunning: boolean;
    enableDriving: boolean;
    drivingLoading: boolean;
    drivingClicked: boolean;
    selectedEnvironment: any;
  }
}

export const setCamera = (camera: string, angle?: number) => {
  console.log("setCamera: " + camera);
  if (!camera) {
    console.error(`Attempting to switch to invalid camera name '${camera}'`);
    return;
  }
  if (window.zlbolt) {
    window.zlbolt.batchChanges(() => {
      window.zlbolt.switchCamera(camera);

      if (angle) {
        window.zlbolt.setRotationIndex(angle);
      }
    });
  }
};

export const getCamera = (): string | undefined => {
  return window.zlbolt?.getCamera();
};

let latestPrCodes: string[] = [];
export const setPrCodes = (prcodes: string[]) => {
  latestPrCodes = prcodes;
  if (window.zlbolt) {
    window.zlbolt.batchChanges(() => {
      window.zlbolt.setPrCodes(prcodes);
      if (prcodes.includes("MZ590914EX")) { // if DRL lights selected, turn DRLs on
        window.zlbolt.setAnimation("DRL", true, true);
      } else {
        window.zlbolt.setAnimation("DRL", false, true);
      }
    });
  }
};

export const setEnvironment = (environment: string) => {
  console.log("setEnvironment: " + environment);
  window.enableDriving = false;
  store.dispatch({ type: Action.INPROGRESS, data: true });
  //if (window.zlurlparams.localstream) { environment=environment.toLowerCase() }
  window.zlbolt?.switchEnvironment(environment);
};

export const cycleEnvironment = () => {
  if (window.zlbolt) {
    window.zlbolt.enumerateEnvironments(function (_: any, e: any) {
      let validEnvs = e.environments.filter(
        (e: string) => !e.includes("Template")
      );
      let nextEnv = validEnvs.indexOf(e.current) + 1;
      let newEnv = validEnvs[nextEnv % validEnvs.length];
      //if (window.zlurlparams.localstream) { newEnv=newEnv.toLowerCase() }
      window.zlbolt.switchEnvironment(newEnv);
    });
  }
};

export const setMode = (mode: ZLBoltMode) => {
  window.zlbolt?.setCurrentMode(mode);
};

export const startDriveSequence = (environment: string) => {
  store.dispatch({ type: Action.DRIVINGLOADING, data: true });
  store.dispatch({ type: Action.INPROGRESS, data: true });
  window.drivingClicked=true;
  console.log("Drive clicked");
  console.log("environment=" + environment);

  if (window.zlurlparams?.scalingprofile) {
    window.zlbolt?.setScalingProfile(window.zlurlparams.scalingprofile, true, true);
  } else {
    window.zlbolt?.setScalingProfile("cinematic", true, true);
  }
  
  //if (window.zlurlparams.localstream) { window.zlbolt?.switchEnvironment("mitsubishi_drivingtemplate"); } else { 
    window.zlbolt?.switchEnvironment("mitsubishi_DrivingTemplate"); 
  //}
  window.zlbolt?.switchCamera("GlobalStageCamera");
  //if (window.zlurlparams.localstream) { environment=environment.toLowerCase() }
  window.selectedEnvironment = environment;
  window.enableDriving = false;
  window.zlbolt?.cloudmine.event({
    type: ["CTA", "CinematicDriving"],
  });
};
export const stopDriveSequence = () => {
  store.dispatch({ type: Action.DRIVINGLOADING, data: true });
  store.dispatch({ type: Action.INPROGRESS, data: true });
  console.log("Stop clicked");
  window.zlbolt?.switchEnvironment(window.selectedEnvironment);
  window.zlbolt?.switchCamera("CloudMRV1");
  
  if (!window.zlurlparams?.scalingprofile) {
    window.zlbolt?.setScalingProfile("landscape", true, true);
  }

  window.sequenceRunning = false;
};
export const pauseDriveSequence = () => {
  window.zlbolt?.pauseCameraSequence();
};

export const triggerAnimation = (animation: string) => {
  window.zlbolt?.triggerAnimation(animation);
};

export const stripQuerystring = (url: string) => {
  return url.split("?")[0].split("#")[0];
}

export const popupTooltip = (seconds: number=20000) => {
  setTimeout(function () {              
    if (!window.drivingClicked) {
      document.getElementById("drivingButton")?.classList.add("show");
      setTimeout(function () {              
        document.getElementById("drivingButton")?.classList.remove("show");
        popupTooltip();
      }, 6000);                
    }
  }, seconds);
};

export const connect = async (
  manufacturer: string,
  model: string,
  year: string,
  initialPrCodes: string[]
): Promise<void> => {
  if (latestPrCodes.length === 0) {
    latestPrCodes = initialPrCodes;
  }
  const connectionOptions = {
     
    recordSessionDetails: true,
    useRegionService: false,
    allow2DFallback: true,
    allow3DUpgrade: false,
    car: {
      manufacturer,
      model,
      year,
    },
    connectionParameters: {
      customer: "mitsubishi",
      environment: window.zlurlparams.env,
      regions: [window.zlurlparams.region],
      protocol: "https",
    },
    cloudmineCameraInteractionsEnabled:true,
    loadingScreen: false,
    clientMarket: "us",
    clientApplication: window.zlurlparams.config || "mitsubishi-configurator",
    idleDisconnect: -1,
    idleDowngrade: window.zlurlparams.timeout,
    idleOverlay: false,
		mouseTracking: {
			scale: { exterior:[100,100,100,100], interior:[100,100,100,100] }
		},
    consumeMouseScrollEvents: false,
    keepLastFrameOnIdleDisconnect: true,
    modes:
    window.zlurlparams.streaming === true ? ["3D"] : ["2D"],
    modeSwitchConfig: {
      transitionType: "crossfade",
    },
    force2DCacheMiss: window.zlurlparams.forcecachemiss,
    onDemand2DConfig: {
      chainFrameBatches: true,
      shortCircuitRailCDNChecks: true,
      showProgressBar: false,
      onDemand2D360ViewRules: {
        loadAuto: true,
        loadOnRotation: false,
        loadOnMoveCamera: false,
      },
    },
    parent: "streamcontainer",
    scalingConfig: {
      showOverflow: false,
      autoScale: true,
      update3DOutputResolution: true,
      useExact3DOutputResolution: true,
      qualitySettings3D: {},
      profiles: {
        landscape: {
          "2D": {
            bounds: { min: [0, 0], max: [9999, 9999] },
            permittedSizes: [
              [1280, 720],
              [1920, 1080],
            ],
            scaleMode: "fill",
          },
          "3D": {
            bounds: { min: [0, 0], max: [9999, 9999] },
            streamResolutionBounds: { min: [0, 0], max: [1920, 1080] },
            aspectRatio: 16 / 9,
            scaleMode: "fill",
          },
        },
        portrait: {
          "2D": {
            bounds: { min: [0, 0], max: [9999, 9999] },
            permittedSizes: [
              [1280, 720],
              [1920, 1080],
            ],
            scaleMode: "fill",
          },
          "3D": {
            bounds: { min: [0, 0], max: [9999, 9999] },
            streamResolutionBounds: { min: [0, 0], max: [1920, 1080] },
            aspectRatio: 16 / 9,
            scaleMode: "fill",
          },
        },
        cinematic: {
          "3D": {
            bounds: { min: [0, 0], max: [9999, 9999] },
            streamResolutionBounds: { min: [0, 0], max: [1280, 720] },
            aspectRatio: 16 / 9,
            scaleMode: "fill",
          },
          "2D": {
            bounds: { min: [0, 0], max: [9999, 9999] },
            permittedSizes: [
              [1280, 720],
              [1920, 1080],
            ],
            scaleMode: "fill",
          },
        },
      },
      profileSwitchingRulesFunc: (cameraName:any, zoomModeEnabled:any) => {
        return window.zlbolt.getCurrentScalingProfile()?.name ?? "landscape";
      },
        
      profileSwitchingRules: {
        default: window.zlurlparams?.scalingprofile
        ? window.zlurlparams.scalingprofile
        : "landscape",        
      },
    },

    richDataStreamConfig: {
      enableCompletionEvents: true,
      enable2DODSupport: false
    },

    logging: {},
    setupCamera: ExteriorCamera,
    setupEnvironment: window.zlurlparams.defaultEnvironment,
    setupPrCodes: latestPrCodes,
    token: undefined as string | undefined,
    directConnect: window.zlurlparams.localstream
      ? window.zlurlparams.localstream + "/zlm/sub/0/ve"
      : undefined, 
    overrideMetadataEndpoint : window.zlurlparams.localstream
      ? "http://"+window.zlurlparams.localstream+"/zlm/localmetadataservice/"
      : undefined,
    localControl : window.zlurlparams.localstream
      ? true
      : false
  };

  let requiredScope: string[] | null = null;
  if (
    ["stage", "assetpreview", "linuxstage"].includes(
      connectionOptions.connectionParameters!.environment
    )
  ) {
    requiredScope = [
      `renderingservice.mitsubishi.${connectionOptions.connectionParameters!.environment
      }.access`,
    ];
    connectionOptions.token = (await GetToken(requiredScope))?.token;
  }

  store.dispatch({
    type: Action.CHANGE_CAMERA,
    data: connectionOptions.setupCamera,
  });
  store.dispatch({
    type: Action.CHANGE_ENVIRONMENT,
    data: connectionOptions.setupEnvironment,
  });

  if (window.zlurlparams.localstream) {
    connectionOptions.localControl = true;
    connectionOptions.modes = ["3D"];
    (connectionOptions.connectionParameters as any).region = "eu-west-1";
    connectionOptions.connectionParameters.protocol = "http";
  }

  return new Promise((resolve, reject) => {
    const libZL = new LibZL();
    libZL
      .zlbolt(connectionOptions.connectionParameters.customer)
      .then((api: any) => {
        //Expose zlbolt for console debugging
        window.zlbolt = api;

        api.movableExteriorCamera = ExteriorCamera;
        api.movableInteriorCamera = InteriorCamera;

        const opts: AddEventListenerOptions & EventListenerOptions = {
          capture: true,
          passive: true,
          once: true,
        };
        const events = ["mousedown", "click", "keypress", "touchstart"];
        const inViewFunc = (ev: Event) => {
          if (ev.isTrusted) {
            console.log("Session started");
            store.dispatch({ type: Action.STARTED, data: true });

            console.log("document.referrer:"+stripQuerystring(document.referrer));

            cloudmine({ type: ["CTA", "SessionStarted", stripQuerystring(document.referrer)] });
            if (api.getCurrentMode() === "2D" && window.zlurlparams.streaming !== false) {
              api.setCurrentMode("3D");
              store.dispatch({ type: Action.UPGRADED, data: true });
            }

            events.forEach(function (name) {
              document.removeEventListener(name, inViewFunc, opts);
            });
          }
        };
        events.forEach(function (name) {
          document.addEventListener(name, inViewFunc, opts);
        });

        api.addEventListener("error", async (error: any) => {
          if (error.errorCode === "TOKEN_INVALID") {
            console.assert(
              requiredScope !== null,
              "Connecting to service for which we don't know the required scope"
            );
            if (requiredScope !== null) {
              const tokenDetails = await Authenticate(requiredScope);
              if (tokenDetails) {
                api.reconnect({ token: tokenDetails.token });
              }
            }
          } else {
            console.warn("ZLBolt had an error: ", error.stack);
          }
        });

        api.addEventListener("streamReady", () => {
          store.dispatch({
            type: Action.CHANGE_MODE,
            data: api.getCurrentMode(),
          });
          console.log("The stream is ready");

          if (window.sequenceRunning === true) {
            stopDriveSequence();
            setTimeout(() => {
              api.setCurrentMode("3D");
            }, 1000);
          }

          if (api.getCurrentMode() === "2D") {
            window.enableDriving = false;
          }
          // workaround to make mouse wheel work as scroll in 2D and zoom in 3D
          if (api.getCurrentMode() === "3D") {
            window.enableDriving = true;
            setTimeout(function () {
              api.options.consumeMouseScrollEvents = true;
              console.log("consumeMouseScrollEvents=true");
            }, 500);
            if (window.zlurlparams?.flashbutton!==false) {
              popupTooltip(5000);
            }
          } else if (
            store.getState().libzl.started &&
            !store.getState().libzl.upgraded && 
            window.zlurlparams.streaming !== false
          ) {
            api.setCurrentMode("3D");
            store.dispatch({ type: Action.UPGRADED, data: true });
          }
          resolve();
        });

        api.addEventListener("cameraSequenceStart", (sequence: string) => {
          console.log("cameraSequenceStart: " + sequence);
        });

        api.addEventListener("environmentChanged", (environment: string) => {
          //api.addEventListener('environmentChange', (state: any, environment: string) => {
          //console.log("environmentChange changed state: "+state+" environment: "+environment);
          //if (state !== 'completed') return;

          store.dispatch({ type: Action.INPROGRESS, data: false });
          store.dispatch({
            type: Action.CHANGE_ENVIRONMENT,
            data: environment,
          });
          if (environment.toLowerCase() === "mitsubishi_drivingtemplate") {
            api.triggerCameraSequence("SequenceChainLoop");
            window.sequenceRunning = true;
          } else {
            if (api.getCurrentMode() === "3D") {
              window.enableDriving = true;
            }
          }
          console.log("environmentChange complete");
          setTimeout(() => {
            console.log("DRIVINGLOADING false");
            store.dispatch({ type: Action.DRIVINGLOADING, data: false });
          }, 1500);

          store.dispatch({ type: Action.INPROGRESS, data: false });
        });

        api.addEventListener(
          "2DProgressBarChanged",
          (
            err: any,
            data: { type: "setup" | "value" | "visibility"; state?: boolean }
          ) => {
            if (data.type === "visibility") {
              store.dispatch({ type: Action.LOADING, data: data.state });
            }
          }
        );

        api.addEventListener("modeChange", (mode: string, status: string) => {
          if (status === "pending") {
            return;
          }

          store.dispatch({ type: Action.CHANGE_MODE, data: mode });
        });

        api.addEventListener("cameraChanged", (camera: string) => {
          store.dispatch({ type: Action.CHANGE_CAMERA, data: camera });
        });

        api.addEventListener("cameraMoving", () => {
          store.dispatch({ type: Action.CAMERA_MOVED, data: true });
        });
        api.addEventListener("cameraMoved", () => {
          store.dispatch({ type: Action.CAMERA_MOVED, data: true });
        });

        api.addEventListener("onStreamDisconnect", () => {
          console.log("Stream disconnected");
        });

        api.addEventListener("prcodesChanged", (prchanges: string) => {
          store.dispatch({ type: Action.INPROGRESS, data: false });
          api.resumeCameraSequence();
          document.body.className = '';
          let prcodes = window.zlbolt.getPrCodes().split(",");
          prcodes.forEach(function(prcode:any) {
            document.body.classList.add(prcode);
          });
        });

        // window.addEventListener("beforeunload", (event) => { api.disconnect(); });
        // window.addEventListener('beforeunload', (event) => { api.setCurrentMode("2D"); });
        window.addEventListener('unload', (event) => { api.disconnect(); });

        connectionOptions.setupPrCodes = latestPrCodes; //Ensure the codes accurately reflect the UI at the time of connection
        api.connect(connectionOptions, (error: any) => {
          if (error) {
            if (connectionOptions.token) {
              LogOut().then(() => window.location.reload());
            }
          }
        });
      });
  });
};

export const getRenderChainUrl = (
  config: { [group: string]: string[] },
  camera: string,
  environment: string,
  width: number = 460,
  height = 320
) => {
  const region = window.zlbolt.options.connectionParameters.region.name;
  const service = window.zlurlparams.env;
  const model = window.zlbolt.options.car.model;
  const year = window.zlbolt.options.car.year;
  const prcodes = `US,${config.trim[0]}_${config.fabric[0]},${config.color[0]}`;
  let url = `https://renderchain-${region}-${service}.mitsubishi.zlthunder.net/api/image?height=${height}&width=${width}&model=${model}&year=${year}&prcodes=${prcodes}&camera=${camera}&env=${environment}`;
  if (window.zlbolt.options.token) {
    url += `&token=${window.zlbolt.options.token}`;
  }
  return url;
};

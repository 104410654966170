import styled, { keyframes } from 'styled-components';

const LoadingAnimation = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`

const Loading = styled.div<{dark?: boolean}>`
	position: absolute;
	top: calc(50% - 24px);
	bottom: calc(50% - 24px);
	left: calc(50% - 24px);
	right: calc(50% - 24px);
	border-left: 3px solid ${props => props.dark === true ? '#000' : '#FFF'};
	border-top: 3px solid ${props => props.dark === true ? '#000' : '#FFF'};
	border-right: 3px solid transparent;
	border-radius: 50%;
	display: none;
	box-sizing: border-box;
	animation: ${LoadingAnimation} 1s linear infinite;
`

export default Loading;
import styled, {css} from 'styled-components';
import { white, black, pressedRed, hoverRed, anthariceGrey, primaryFont, Breakpoints } from './Constants';

const Container = styled.button<{altStyle: boolean, small: boolean, squashed: boolean}>`
	appearance: none;
	outline: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: auto;

	font-family: ${primaryFont};
	font-weight: 700;
	letter-spacing: 0.25em;
	text-align: left;

	font-size: 14px;
	line-height: 17px;
	padding: 14px 40px;

	${props => props.altStyle ?
		css`
			color: ${white};
			background-color: transparent;
			border: 2px solid ${white};
			&:hover, &:focus {
				background-color: ${hoverRed};
				border: 2px solid ${hoverRed};
				color: ${white};
			}
			&:active {
				background-color: ${anthariceGrey};
				border: 2px solid ${anthariceGrey};
				color: ${white};
			}
		`
		:
		css`
			color: ${white};
			background-color: ${black};
			&:hover, &:focus {
				background-color: ${hoverRed};
			}
			&:active {
				background-color: ${pressedRed};
			}
		`
	}
	${props => (props.altStyle || props.small) && css`
		@media ${Breakpoints.Mobile} {
			font-size: 12px;
			line-height: 15px;
			padding: 10px 15px;
		}
		@media ${Breakpoints.SmallMobile} {
			font-size: 9px;
			line-height: 10px;
			padding: 10px 13px;
		}		
	`}
	${props => (props.squashed) && css`
		letter-spacing: 0.19em;
		padding: 14px 25px;
	`}
`;


interface IProps {
	id: string
	label: string
	alt?: boolean
	small?: boolean
	squashed?: boolean
	onClick: () => void
}

export default function Button(props: IProps) {
	return (
		<Container aria-label={props.label} data-automationid={props.id} onClick={props.onClick} altStyle={props.alt===true} small={props.small===true} squashed={props.squashed===true}>
			{props.label}
		</Container>
	)
}

Button.defaultProps = {
	alt: false
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './zlurlparams.ts'

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement    
);

root.render(
	<App />
);

if (window.zlurlparams.config && window.zlurlparams.config!=="") {
    let head  = document.getElementsByTagName('head')[0];
    let link  = document.createElement('link');
    link.rel  = 'stylesheet';
    link.type = 'text/css';
    link.href = './css/override'+window.zlurlparams.config+'.css';
    link.media = 'all';	
    head.appendChild(link);
}

if (window.zlurlparams.css && window.zlurlparams.css!=="") {
    let head  = document.getElementsByTagName('head')[0];
    let link  = document.createElement('link');
    link.rel  = 'stylesheet';
    link.type = 'text/css';
    link.href = './css/override'+window.zlurlparams.css+'.css';
    link.media = 'all';	
    head.appendChild(link);
}

if (window.zlurlparams.brochure === true) {
    document.documentElement.className += ' brochureform';
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState } from "react";
import CloseImage from "./assets/icons/closeBtn.svg";
import axios from "axios";
import { startPdfCreation, attemptDownload } from "./pdf";
import styled, {css} from "styled-components";
import { primaryFont,Breakpoints } from "./Constants";
import Loading from "./Loading";
import { useAppSelector } from "./hooks";
import { getI18nString } from "./helpers";

export const Wrapper = styled(`div`)`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 62px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  right: 0;
  background: #fff;
`;
export const Row = styled(`div`)`
  display: flex;
  @media ${Breakpoints.Mobile} {
    display: block;
  }
`;
export const Col = styled(`div`)`
  flex: 1;
  margin: 0 1em;
  position: relative;
`;
export const P = styled(`p`)`
  font-family: ${primaryFont};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin: 0;
  color: #777e86;
  text-align: center;
  margin-top: 6em;
  a {
    color: #000;
    text-decoration: none;
    font-weight: 900;
  }
`;
export const CustomBtn = styled(`button`)`
  font-family: ${primaryFont};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  padding: 10px 40px;
  gap: 10px;

  width: auto;
  height: 50px;
  display: block;
  margin: auto;
  display: flex;
  align-items: center;
  background: #000000;
  color: #ffffff;
  transition: 200ms all ease-in-out;
  > div {
    position: relative;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    width: 30px;
    height: 30px;
  }
  &:hover {
    background: #da2e20;
  }
`;
export const CustomCheckBox = styled(`label`)<{subtitle?: string}>`
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 20px 0px 30px;
  line-height: 27px;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #777e86;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #777e86;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #000;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  ${props => css`
  &:after {
    content: "${props.subtitle}" !important
    }
  `}
  ${props => props.subtitle && css`&:after {
    content: "${props.subtitle}" !important
    }`
  }
`;
export const CustomInput = styled(`input`)`
  border: 0;
  padding: 10px 0px;
  gap: 10px;
  width: 100%;
  height: 36px;
  background: #ffffff;
  border-bottom: 1px solid #777e86;
  margin-bottom: 3em;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15em;
  outline: none;
  color: #777e86;
  :placeholder {
    text-transform: uppercase;
  }
`;
export const Validation = styled(`h6`)`
  color: #da2e20;
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100vw;
`;
export const H1 = styled(`h1`)`
  font-family: ${primaryFont};
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  margin: 0;
  color: #000000;
`;
export const FormContainer = styled(`div`)`
  width: 100%;
  max-width: 850px;
  margin: auto;
  padding-bottom: 30px;
`;
export const H6 = styled(`h6`)`
  font-family: ${primaryFont};
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin: 0;
  margin-bottom: 15px;
  margin-top: 22px;
  color: #777e86;
`;
export const CloseBtn = styled(`a`)`
  position: absolute;
  top: 25px;
  right: 25px;
  display: inline-block;
`;
export const CustomButton = styled(`button`)`
  font-family: ${primaryFont};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: #000000;
  border: 1px solid #000000;
  padding: 1em;
  margin-top: 10px;
  transition: 200ms all ease-in-out;
  &:hover {
    background: #da2e20;
    color: #fff;
    border-color: #da2e20;
  }
`;

interface IProps {
  setVisible: any;
  showThankyouMessage: any;
  visible: any;
  prcodes: string[];
}

declare global {
  interface Window {
    brochureFormOpen: boolean;
  }
}

export default function Form({
  setVisible,
  visible,
  prcodes,
  showThankyouMessage,
}: IProps) {
  const [pdfCreateStarted, setPdfCreateStarted] = useState(false);
  const [name, setName] = useState("");
  const [nameValidation, setNameValidation] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameValidation, setLastNameValidation] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValidation, setEmailValidation] = useState(false);
  const [zip, setZip] = useState("");
  const [digitalBrochureOption, setDigitalBrochureOption] = useState(false);
  // const [digitalBrochureOptionValidation, setDigitalBrochureOptionValidation] =
  //   useState(false);
  const [pdfOption, setPdfOption] = useState(false);
  const [optionType, setOptionType] = useState<any>([]);

  const [pdfOptionValidation, setPdfOptionValidation] = useState(false);
  const [consent, setConsent] = useState(false);
  const [consentValidation, setConsentValidation] = useState(false);
  const [buttonText, setButtonText] = useState("Request");
  const config = useAppSelector(s => s.config!)

  const onFinish = async () => {
    if (name && lastName && email && (pdfOption || digitalBrochureOption)) {
      if (!consent) {
        setConsentValidation(true);
      } else {
        if (pdfOption) {
          if (pdfCreateStarted) setButtonText("Creating Your PDF...");
          attemptDownload(
            setButtonText,
            setVisible,
            setPdfCreateStarted,
            digitalBrochureOption
          );
        }

        let crmOfferType = config.crmOffer.pdfCode; // Needed by Critical Mass for MMNA CRM - DGEBDN for PDF only or DGEBEM if includes a microsite
        let micrositeParams = "";
        if (digitalBrochureOption) {
          crmOfferType = config.crmOffer.micrositeCode;
          if (buttonText === "Request") {
            setButtonText("Requesting Your Brochure...");
          }
          try {
            // Spotlight Webhook Docs - https://zerolight.atlassian.net/browse/ZLCC-4883?focusedCommentId=193020
            const response = await fetch(
              "https://spotlightproxy-mitsubishi-brochure.mitsubishi.zlthunder.net/orders/create",
              {
                method: "POST",
                body: JSON.stringify({
                  data: {
                    type: "microsite",
                    template: `microsite-mitsubishi-${window.zlurlparams.model}_${window.zlurlparams.year}`,
                    car: `mitsubishi ${window.zlurlparams.model} ${window.zlurlparams.year}`,
                    prcodes: prcodes.join(","),
                    parameters: [],
                    callback: {
                      // Salesforce listener docs - https://developer.salesforce.com/docs/marketing/marketing-cloud/guide/insertDataExtensionIDAsync.html
                      uri: "https://genericproxy-salesforcewebhook.mitsubishi.zlthunder.net/data/v1/async/dataextensions/key:ZeroLight/rows",
                      method: "POST",
                      /*
                    body: { // currently not used as goes through the proxy
                      items: [
                        {
                          orderId: "${orderId}",    // eslint-disable-line no-template-curly-in-string
                          status: "${status}"           // eslint-disable-line no-template-curly-in-string
                        }
                      ]
                    }
                    */
                      body: {
                        // Used proxy as SpotLight didn't support arbitrary bodies - could be swapped back now
                        orderId: "${orderId}", // eslint-disable-line no-template-curly-in-string
                        status: "${status}", // eslint-disable-line no-template-curly-in-string
                      },
                    },
                  },
                }),
                headers: {
                  "x-api-key": "WTfkLMbTzf2KQDWHTkc4U4hklWFjI8nW405TOL3R",
                  "Content-Type": "application/vnd.api+json",
                },
              }
            );
            if (response.ok) {
              const data = await response.json();
              micrositeParams =
                `, { fieldName: "micrositeID" value: "${data.data.id}" type: "string" }` +
                `, { fieldName: "micrositeURL" value: "${data.data.items[0].uri
                }&name=${encodeURIComponent(name)}" type: "string" }`;
              console.log("Microsite Params: " + micrositeParams);
              window.zlbolt?.cloudmine?.event({
                type: ['CTA', 'MicrositeRequest'],
                micrositeID: data.data.id,
                micrositeURL: data.data.items[0].uri
              });
            } else {
              console.error("Failed to request microsite generation");
            }
          } catch (ex) {
            console.error("Failed to request microsite generation", ex);
          }
        }

        //For Outlander PHEV the submit path is /us/en/cars-and-suvs/outlander-phev
        //For Outlander the submit path is /us/en/cars-and-suvs/outlander

        if (window.zlurlparams.offerCode)
          crmOfferType = window.zlurlparams.offerCode;// TODO remove once Critical Mass fix email sending

        // GraphQL query to send to Critical Mass
        const data = {
          query:
            `mutation { submitForm(
                market:"us", 
                language:"en",
                path:"${config.crmOffer.path}", 
                form: { 
                  leadFormType: "${crmOfferType}" 
                  leadFormDescription: "${crmOfferType}" 
                  leadFields: [
                    { fieldName:"email" value: "${email}" type: "string" }, 
                    { fieldName:"firstName" value: "${name}" type: "string" }, 
                    { fieldName:"lastName" value: "${lastName}" type: "string" }, 
                    { fieldName:"zipcode" value: "${zip}" type: "string" }, 
                    { fieldName:"requestPDF" value: "${pdfOption}" type: "string" }, 
                    { fieldName:"requestMicrosite" value: "${digitalBrochureOption}" type: "string" }, 
                    { fieldName:"emOptIn" value: "${consent}" type: "string" }, 
                    { fieldName:"pr" value: "${prcodes.join(",")}" type: "string" },
                    { fieldName:"model" value: "${config.crmOffer.model}" type: "string" },
                    { fieldName:"year" value: "${window.zlurlparams.year}" type: "string" },
                    ${micrositeParams}
                  ] }) 
                  { statusCode errorMessages { code message fieldName } thankYouPageURI 
                }
              }`.replace(/\n/gi, "")
        };

        await axios.post(
          "https://graphql.live.prod.mipulse.co/prod/graphql",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (digitalBrochureOption) {
          showThankyouMessage();
        }
      }
    } else {
      setNameValidation(true);
      setLastNameValidation(true);
      setEmailValidation(true);
      // setDigitalBrochureOptionValidation(true);
      if (!optionType.length) {
        setPdfOptionValidation(true);
      }
      // setConsentValidation(true);
    }
  };
  const handleDigitalBrochureOption = (e: any) => {
    console.log(!e.target.checked);
    setPdfOptionValidation(!e.target.checked);
    setOptionType(
      e.target.checked
        ? [...optionType, "digital"]
        : optionType.filter((item: any) => item !== "digital")
    );
    setDigitalBrochureOption(e.target.checked);
  };
  const handlePdfOption = (e: any) => {
    console.log(e.target.checked);
    setPdfOptionValidation(!e.target.checked);
    setPdfOption(e.target.checked);
    setOptionType(
      e.target.checked
        ? [...optionType, "pdf"]
        : optionType.filter((item: any) => item !== "pdf")
    );
    assessPdfDownload(e.target.checked);
  };
  console.log(pdfOptionValidation);
  const handleConsent = (e: any) => {
    setConsentValidation(e.target.checked);
    setConsent(e.target.checked);
  };
  const assessPdfDownload = (pdfClicked: boolean) => {
    if (!pdfCreateStarted && name !== "" && (pdfClicked || pdfOption)) {
      setPdfCreateStarted(true);
      startPdfCreation(name, prcodes);
    }
  };
  return (
    <Wrapper id="requestbrochure">
      <CloseBtn
        href="#"
        onClick={(e) => {
          e.preventDefault();
          window.brochureFormOpen = false;
          setVisible(false);
        }}
      >
        <img src={CloseImage} alt="" />
      </CloseBtn>
      <FormContainer id="formcontainer">
        <H1>Get a Brochure</H1>
        <H6>
          Enter your details below to receive your personalized, interactive
          digital brochure or PDF.
        </H6>
        <div style={{ position: "relative", padding: "1em 0" }}>
          {(!digitalBrochureOption &&
            pdfOptionValidation &&
            !optionType.length) ||
            (!pdfOption && pdfOptionValidation && !optionType.length) ? (
            <Validation id="brochurePDFvalidation">
              Please choose either a personalized interactive brochure or a PDF, or both.
            </Validation>
          ) : null}
        </div>

        <Row>
          <Col>
            <CustomCheckBox
              id="digitalBrochureContainer"
              htmlFor="digitalBrochureOption"
              subtitle={`Receive a personalized interactive page with details of your unique ${getI18nString(config.brochureName)} build.`}
            >
              PERSONALIZED INTERACTIVE BROCHURE
              <input
                type="checkbox"
                id="digitalBrochureOption"
                name="digitalBrochureOption"
                onChange={handleDigitalBrochureOption}
              />
              <span className="checkmark"></span>
            </CustomCheckBox>
          </Col>
          <Col>
            <CustomCheckBox 
              id="pdfBrochureContainer" 
              htmlFor="pdfOption"
              subtitle={`Download a PDF with details of your unique ${getI18nString(config.brochureName)} build.`}
            >
              PERSONALIZED PDF DOWNLOAD
              <input
                type="checkbox"
                id="pdfOption"
                name="pdfOption"
                onChange={handlePdfOption}
              />
              <span className="checkmark"></span>
            </CustomCheckBox>
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomInput
              type="text"
              placeholder="FIRST NAME *"
              onChange={(e) => {
                setNameValidation(true);
                setName(e.target.value);
              }}
              onFocus={() => {
                // this is just temporary while microsites are not available
                //setPdfOption(true);
              }}
              onBlur={() => {
                // start generating the PDF as soon as they exit the name field
                assessPdfDownload(false);
              }}
            />
            {!name && nameValidation ? (
              <Validation>Please input your first name</Validation>
            ) : null}
          </Col>
          <Col>
            <CustomInput
              type="text"
              placeholder="LAST NAME *"
              onChange={(e) => {
                setLastNameValidation(true);
                setLastName(e.target.value);
              }}
            />
            {!lastName && lastNameValidation ? (
              <Validation>Please input your last name</Validation>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomInput
              type="text"
              placeholder="EMAIL *"
              onChange={(e) => {
                setEmailValidation(true);
                setEmail(e.target.value);
              }}
            />
            {!email && emailValidation ? (
              <Validation>Please input your email</Validation>
            ) : email && emailValidation ? (
              email.includes("@") ? null : (
                <Validation id="emailvalidation">Please enter a valid email address</Validation>
              )
            ) : null}
          </Col>
          <Col>
            <CustomInput
              type="text"
              placeholder="ZIP"
              onChange={(e) => setZip(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomCheckBox id="consentlabel" htmlFor="consent">
              YES, I CONSENT TO RECEIVING PRODUCT INFORMATION AND EXCLUSIVE NEWS BY EMAIL.
              <input
                type="checkbox"
                id="consent"
                name="consent"
                onChange={handleConsent}
              />
              <span className="checkmark"></span>
            </CustomCheckBox>
            {!consent && consentValidation ? (
              <Validation id="consentvalidation">We require your consent to send your Personalized Interactive Brochure by email. Please check the box.</Validation>
            ) : null}
          </Col>
        </Row>
        <CustomBtn id="requestButton" onClick={onFinish}>
          {buttonText === "Creating Your PDF..." ? (
            <>
              <Loading /> {buttonText}
            </>
          ) : buttonText === "Requesting Your Brochure..." ? (
            <>
              <Loading /> {buttonText}
            </>
          ) : (
            buttonText
          )}
        </CustomBtn>
        <P id="brochureterms">
          BY SUBMITTING THIS FORM YOU AGREE TO OUR{" "}
          <a
            href="https://www.mitsubishicars.com/terms"
            rel="noreferrer"
            target="_blank"
          >
            TERMS OF USE
          </a>{" "}
          AND OUR{" "}
          <a
            href="https://www.mitsubishicars.com/privacy"
            rel="noreferrer"
            target="_blank"
          >
            PRIVACY POLICY
          </a>
        </P>
      </FormContainer>
    </Wrapper>
  );
}

import React from 'react';
import styled from 'styled-components';
import { Breakpoints } from './Constants';

const Container = styled.div<{type: 'text' | 'mixed' | 'icon'}>`
	display: grid;
	grid-template-columns: ${props => props.type === 'icon' ? '1fr 1fr' : '1fr'};
	
	@media ${Breakpoints.Tablet} and (orientation: portrait)/*, screen and (min-aspect-ratio: 20/10)*/ {
		${props => props.type === 'icon' && "grid-template-columns: 1fr 1fr 1fr 1fr"};
		${props => props.type === 'mixed' && "grid-template-columns: 1fr 1fr"};
	}
	@media ${Breakpoints.Mobile} and (orientation: portrait) {
		grid-template-columns: ${props => props.type === 'icon' ? '1fr 1fr' : '1fr'};
	}

	grid-column-gap: 10px;
	grid-row-gap: 20px;
	${props => props.type === 'icon' && "grid-auto-rows: 76px"};
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 10px;
`;

interface IProps {
	type: 'text'|'mixed'|'icon'
	children: Iterable<React.ReactNode>
}

export default function OptionGroup(props: IProps) {
	return (
		<Container type={props.type}>
			{props.children}
		</Container>
	)
}
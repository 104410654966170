import TextOption from "./TextOption";
import IconOption from "./IconOption";
import MixedOption from "./MixedOption";

interface IProps {
  type: string;
  id: string;
  name: string;
  description?: string;
  icon?: string;
  selected: boolean;
  included: boolean;
  visual: boolean;
  inProgress: boolean;
  onClick: () => void;
}

export default function Option(props: IProps) {
  if (props.type === "icon") {
    return <IconOption {...props} paint />;
  } else if (props.type === "mixed") {
    return <MixedOption {...props} />;
  } else {
    return <TextOption {...props} />;
  }
}

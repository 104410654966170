import React from "react";

import { ReactComponent as Environments } from "./assets/icons/Environments.svg";
import { ReactComponent as BeautyShots } from "./assets/icons/BeautyShots.svg";
import { ReactComponent as ExteriorCamera } from "./assets/icons/ExteriorCamera.svg";
import { ReactComponent as InteriorCamera } from "./assets/icons/InteriorCamera.svg";

import paint_C2X from './assets/icons/paints/C2X.jpg';
import paint_C17 from './assets/icons/paints/C17.jpg';
import paint_D14 from './assets/icons/paints/D14.jpg';
import paint_P62 from './assets/icons/paints/P62.jpg';
import paint_U17 from './assets/icons/paints/U17.jpg';
import paint_U25 from './assets/icons/paints/U25.jpg';
import paint_W85 from './assets/icons/paints/W85.jpg';
import paint_X42 from './assets/icons/paints/X42.jpg';
import paint_X47 from './assets/icons/paints/X47.jpg';
import paint_XP2 from './assets/icons/paints/XP2.jpg';
import paint_XU5 from './assets/icons/paints/XU5.jpg';
import paint_XU7 from './assets/icons/paints/XU7.jpg';
import paint_XW4 from './assets/icons/paints/XW4.jpg';
import paint_U2X from './assets/icons/paints/U2X.jpg';
import paint_U28 from './assets/icons/paints/U28.jpg';
import paint_XFU from './assets/icons/paints/XFU.jpg';

import interior_43L from './assets/icons/interiors/43L.jpg';
import interior_43P from './assets/icons/interiors/43P.jpg';
import interior_43X from './assets/icons/interiors/43X.jpg';
import interior_44X from './assets/icons/interiors/44X.jpg';
import interior_45J from './assets/icons/interiors/45J.jpg';
import interior_06P from './assets/icons/interiors/06P.jpg';

import camera_dge_2023_ConfigureExterior1 from './assets/icons/cameras/dge_2023/ConfigureExterior1.jpg';
import camera_dge_2023_Cloud_FrontRightShare from './assets/icons/cameras/dge_2023/Cloud_FrontRightShare.jpg';
import camera_dge_2023_Wheels_Low from './assets/icons/cameras/dge_2023/Wheels_Low.jpg';
import camera_dge_2023_InteriorSide_L0L from './assets/icons/cameras/dge_2023/InteriorSide_L0L.jpg';
import camera_dge_2023_Interior_Rear_View from './assets/icons/cameras/dge_2023/Interior_Rear_View.jpg';
import camera_dge_2023_Interior_01 from './assets/icons/cameras/dge_2023/Interior_01.jpg';

import brooklyn_waterfront_day from './assets/icons/environments/brooklyn_waterfront_day.jpg';
import cape_town_apartment from './assets/icons/environments/cape_town_apartment.jpg';
import glacier_point_mitsubishi from './assets/icons/environments/glacier_point_mitsubishi.jpg';
import mitsubishi_studio from './assets/icons/environments/mitsubishi_studio.jpg';
import zabriskie_usa3735 from './assets/icons/environments/zabriskie_usa3735.jpg';


const icons : {[key: string]: React.ReactNode} = {
	environments: (<Environments />),
	beautyshots: (<BeautyShots />),
	exteriorcamera: (<ExteriorCamera />),
	interiorcamera: (<InteriorCamera />),

	"C2X": (<img src={paint_C2X} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"C17": (<img src={paint_C17} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"D14": (<img src={paint_D14} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"P62": (<img src={paint_P62} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"U17": (<img src={paint_U17} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"U25": (<img src={paint_U25} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"W85": (<img src={paint_W85} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"X42": (<img src={paint_X42} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"X47": (<img src={paint_X47} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"XP2": (<img src={paint_XP2} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"XU5": (<img src={paint_XU5} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"XU7": (<img src={paint_XU7} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"XW4": (<img src={paint_XW4} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"U2X": (<img src={paint_U2X} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"U28": (<img src={paint_U28} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),
	"XFU": (<img src={paint_XFU} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}}/>),

	"43L": (<img src={interior_43L} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}} />),
	"43P": (<img src={interior_43P} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}} />),
	"43X": (<img src={interior_43X} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}} />),
	"44X": (<img src={interior_44X} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}} />),
	"45J": (<img src={interior_45J} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}} />),
	"06P": (<img src={interior_06P} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%'}} />),


	DGE2023_ConfigureExterior1: (<img src={camera_dge_2023_ConfigureExterior1} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%'}}/>),
	DGE2023_Cloud_FrontRightShare: (<img src={camera_dge_2023_Cloud_FrontRightShare} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%'}}/>),
	DGE2023_Wheels_Low: (<img src={camera_dge_2023_Wheels_Low} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%'}}/>),
	DGE2023_InteriorSide_L0L: (<img src={camera_dge_2023_InteriorSide_L0L} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%'}}/>),
	DGE2023_Interior_Rear_View: (<img src={camera_dge_2023_Interior_Rear_View} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%'}}/>),
	DGE2023_Interior_01: (<img src={camera_dge_2023_Interior_01} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%'}}/>),

	brooklyn_waterfront_day: (<img src={brooklyn_waterfront_day} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	cape_town_apartment: (<img src={cape_town_apartment} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	glacier_point_mitsubishi: (<img src={glacier_point_mitsubishi} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_C2X: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_C17: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_D14: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_P62: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_U17: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_U25: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_W85: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_X42: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_X47: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_XP2: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_XU5: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_XU7: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	mitsubishi_studio_XW4: (<img src={mitsubishi_studio} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
	zabriskie_usa3735: (<img src={zabriskie_usa3735} alt="" style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}}/>),
}

export const getIcon = (id: string, alt: string = "", fallback?: string) => {
	if(id) {
		if(icons[id]) {
			return icons[id];
		}
		else if(fallback && icons[fallback]) {
			return icons[fallback]
		}
		if(id.startsWith("/") || id.startsWith("http")) {
			return <img src={id} alt={alt} style={{objectFit: 'cover', maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%'}} onError={fallback && fallback!==id ? (e) => e.currentTarget.src=fallback : undefined}/>
		}
	}
	return undefined;
}
import styled, {css} from 'styled-components';
import CheckBox from './Checkbox';
import { getIcon } from './Icons';
import { ReactComponent as CheckboxIcon } from "./assets/Checkbox.svg";
import { hoverRed } from './Constants';

const Container = styled.button<{showSelectionLine: boolean; inProgress: boolean}>`
	appearance: none;
	outline: none;
	text-align: left;
	position: relative;
	width: 100%;
	height: 100%;

	cursor: ${(props) => (props.inProgress ? "not-allowed" : "pointer")};

	${props => props.showSelectionLine && css`
		&::before {
			content: "";
			position: absolute;
			height: 2px;
			width: 100%;
			background-color: ${hoverRed};
			z-index: 1;
		}
	`}
`;

const ThumbnailContainer = styled.div<{ inProgress: boolean }>`

	pointer-events: ${(props) => (props.inProgress ? "none" : "auto")};
	opacity: ${(props) => (props.inProgress ? "0.6" : "1")};

	position: relative;
	width: 100%;
	height: 100%;
`

interface IProps {
	id: string
	name: string
	icon?: string
	fallbackIcon?: string
	paint?: boolean
	selected: boolean
	included?: boolean
	onClick: () => void
	inProgress: boolean
}

export default function Option(props: IProps) {
	return (
		<Container 
			aria-label={props.name} 
			data-automationid={props.id} 
			onClick={props.onClick} 
			showSelectionLine={props.selected && props.paint !== true} 
			title={props.name} 
			disabled={props.inProgress ? true : props.included}
			inProgress={props.inProgress}
		>
			<ThumbnailContainer inProgress={props.inProgress}>
				{getIcon(props.icon as string, props.name, props.fallbackIcon)}
			</ThumbnailContainer>
			<CheckBox selected={props.selected} included={props.included===true} style={{position: 'absolute', top: 8, right: 8}}>
				<CheckboxIcon/>
			</CheckBox>
		</Container>
	)
}
import styled from 'styled-components';

const CheckBox = styled.div<{selected: boolean, included: boolean}>`
	background-color: transparent;
	visibility: ${props => props.selected ? 'visible' : 'hidden'};
	width: 25px;
	height: 25px;
	flex: 0 0 auto;
	margin-left: 10px;
`

export default CheckBox;
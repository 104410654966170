import './zlurlparams.ts'

export const backgroundColour = "#ffffff"
export const dividerColour = "#222222"
export const textColour = "#111111"
export const buttonDetailColour = "#ffffff"
export const altTextColour = "#131313"
export const highlightColour = "#ff0000"

export const black = "#000000"
export const white = "#ffffff"
export const lightGrey = "#f6f6f6"
export const coldGrey = "#bfc2c4"
export const anthariceGrey = "#686d71"
export const red = "#ed0000"
export const hoverRed = "#da2e20"
export const pressedRed = "#a92e2b"

export const primaryFont = ['MMC', 'Helvetica', 'sans-serif'].join(",")

export enum Breakpoints {
	SmallMobile = "screen and (max-width: 500px)",
	Mobile = "screen and (max-width: 767px)",
	Tablet = "screen and (max-width: 1024px)",
	Desktop = "screen and (min-width: 1366px)"
}

export const ExteriorCamera = "CloudMRV1"
export const InteriorCamera = "Interior"

export const Environments = [
	{
		"id": "cape_town_apartment",
		"name": {
			"default": "Cape Town Apartment"
		},
		"icon": "cape_town_apartment"
	},
	{
		"id": "glacier_point_mitsubishi",
		"name": {
			"default": "Glacier Point"
		},
		"icon": "glacier_point_mitsubishi"
	},
	{
		"id": "brooklyn_waterfront_day",
		"name": {
			"default": "Brooklyn Waterfront"
		},
		"icon": "brooklyn_waterfront_day"
	},
	{
		"id": "Zabriskie_USA3735",
		"name": {
			"default": "Zabriskie"
		},
		"icon": "zabriskie_usa3735"
	},
	{
		"id": "mitsubishi_studio",
		"name": {
			"default": "Studio"
		},
		"icon": "mitsubishi_studio"
	}
]

import React, {Component} from 'react';

import {IntlProvider, IntlShape, MessageDescriptor} from "react-intl";

import en_US from "./strings/compiled/en-US.json"

const strings : {[locale: string]: any} = {
	"en-US": en_US
};

interface Props {
	children?: React.ReactNode
}

class StringProvider extends Component<Props> {
	render() {
		const locale = window.zlurlparams.locale;
		const elements : any = {
			br: (_: string) => <br/>,
			b: (str: string) => <b>{str}</b>
		}
		return (
			<IntlProvider locale={locale} messages={strings[locale] || strings["en-US"]} defaultRichTextElements={elements}>
				{this.props.children}
			</IntlProvider>
		);
	}
}


export function formatMessage(intl: IntlShape, message: MessageDescriptor, values?: Record<string, any> | undefined) : React.ReactNode {
	return intl.formatMessage(message, values);
}

export default StringProvider;
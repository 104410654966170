import { useEffect, useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import styled from 'styled-components';
import Button from './Button';
import { primaryFont, Breakpoints, white } from './Constants';
import { formatMessage } from './Strings';
import messages from './strings/messages';
import {ReactComponent as MouseRotate} from './assets/icons/MouseDrag.svg';
import {ReactComponent as MouseZoom} from './assets/icons/MouseZoom.svg';
import {ReactComponent as TouchRotate} from './assets/icons/TouchDrag.svg';
import {ReactComponent as TouchZoom} from './assets/icons/TouchZoom.svg';
import {ReactComponent as DeviceRotate} from './assets/icons/RotateDevice.svg';
import { useAppSelector } from './hooks';

const INSTRUCTIONS_DELAY = 15000;//ms

const Container = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 5;
	background-color: rgba(0,0,0,0.68);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	pointer-events: none;
`

const DeviceRotateContainer = styled(Container)`
	display: none;
	@media ${Breakpoints.Mobile} and (orientation: landscape) and (pointer: coarse) {
		display: flex;
	}
`

const MouseIcon = styled.div`
	display: none;
	@media (pointer: fine) {
		display: block;
	}
`
const TouchIcon = styled.div`
	display: none;
	@media (pointer: coarse) {
		display: block;
	}
`

const Label = styled.div`
	color: ${white};
	font-family: ${primaryFont};

	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: left;

	margin-top: 20px;
	margin-bottom: 46px;


	@media ${Breakpoints.Mobile} {
		font-size: 21px;
	}
`

interface IProps {
	id: string;
	intl: IntlShape
}

function Instructions(props: IProps) {
	const [rotate, setRotate] = useState<boolean>(false)
	const [zoom, setZoom] = useState<boolean>(false)
	const [landscape, setLandscape] = useState<boolean>(false)
	useEffect(() => {
		const handle = setInterval(() => {
			if(window.matchMedia(`${Breakpoints.Mobile} (orientation: landscape)`).matches) {
				setLandscape(true)
			}
		}, 100)

		return () => clearInterval(handle)
	}, []);

	const [delayElapsed, setDelayElapsed] = useState<boolean>(false)
	const started = useAppSelector(state => state.libzl.started)
	useEffect(() => {
		if(started) {
			const handle = setTimeout(() => {
				setDelayElapsed(true);
			}, INSTRUCTIONS_DELAY)
			return () => clearTimeout(handle);
		}
		return;
	}, [started])

	const moved = useAppSelector(store => store.libzl.moved)
	const currentMode = useAppSelector((store) => store.libzl.mode)


	if(!rotate && !moved) {
		return (
			<Container id="instructions">
				<MouseIcon><MouseRotate/></MouseIcon>
				<TouchIcon><TouchRotate/></TouchIcon>
				<Label>{formatMessage(props.intl, messages.instructionsrotate)}</Label>
				<Button id="skip" label={formatMessage(props.intl, messages.skip) as string} onClick={() => setRotate(true)} alt />
			</Container>
		);
	} else {
		var spinner = document.getElementById('spinner')
		if (spinner) spinner.style.display = "inline-block";		
	}
	if(delayElapsed && window.enableDriving && !zoom && !moved && currentMode === "3D") {
		return (
			<Container>
				<MouseIcon><MouseZoom/></MouseIcon>
				<TouchIcon><TouchZoom/></TouchIcon>
				<Label>{formatMessage(props.intl, messages.instructionszoom)}</Label>
				<Button id="skip" label={formatMessage(props.intl, messages.skip) as string} onClick={() => setZoom(true)} alt />
			</Container>
		);
	}
	if(!landscape) {
		return (
			<DeviceRotateContainer>
				<TouchIcon><DeviceRotate/></TouchIcon>
				<Label>{formatMessage(props.intl, messages.instructionslandscape)}</Label>
				<Button id="skip" label={formatMessage(props.intl, messages.skip) as string} onClick={() => setLandscape(true)} alt />
			</DeviceRotateContainer>
		);
	}
	return null;
}

export default injectIntl(Instructions)
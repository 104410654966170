import React, { useEffect, useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import styled, { css } from "styled-components";

import {
  analyticsMarker,
  Config,
  ConfigGroup,
  ConfigGroupOption,
  getAnalyticsName,
  getCameraType,
  getI18nString,
  localStorageSetItem,
  postMessage,
} from "./helpers";
import { formatMessage } from "./Strings";
import Collapsible from "./Collapsible";
import OptionGroup from "./OptionGroup";
import Option from "./Option";
import SelectedOption from "./SelectedOption";
import {
  backgroundColour,
  Breakpoints,
  lightGrey,
  coldGrey,
  anthariceGrey,
  ExteriorCamera,
  InteriorCamera,
  Environments,
  white,
} from "./Constants";
import {
  connect,
  setCamera,
  setPrCodes,
  setMode,
  startDriveSequence,
  stopDriveSequence,
  pauseDriveSequence,
  setEnvironment,
  getRenderChainUrl,
} from "./libzl";
import Button from "./Button";
import Loading from "./Loading";
import messages from "./strings/messages";
import { useAppSelector } from "./hooks";
import IconOption from "./IconOption";
import ToggleButton from "./ToggleButton";
import TrayButton from "./TrayButton";
import Instructions from "./Instructions";
import { ZLBoltMode } from "@zerolight-core/libzl";
import { cloudmine } from "./helpers";
import mitsubishilogo from "./assets/icons/mitsubishilogo.png";
import loadingbar from "./assets/icons/loadingbar.gif";
import { CustomButton } from "./Form";
import Form from "./Form";
import ThankyouMessage from "./ThankyouMessage";
import Message from "./Message";
import store, { Action } from "./Store";

declare global {
  interface Window {
    brochureFormOpen: boolean;
  }
}

enum TrayMode {
  Environments = "Environments",
  BeautyShots = "BeautyShots",
}

const Root = styled.div`
  background-color: ${backgroundColour};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  height: 100%;
`;

const MainConfig = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
  align-items: stretch;
  max-height: 100%;

  @media screen and (orientation: portrait) {
    flex-direction: column;
    height: unset;
    //max-height: unset;
  }
`;

const Details = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1 1 0;

  @media screen and (orientation: landscape) {
    width: 366px;
    max-width: 366px;
  }
  /*
	@media screen and (min-aspect-ratio: 20/10) {
		width: 366px;
		max-width: 366px;
	}
	*/
  overflow: hidden;
  @media ${Breakpoints.Mobile} and (orientation: portrait) {
    overflow: visible;
    flex: 0 1 0;
  }

  @media ${Breakpoints.Mobile} and (pointer: coarse) and (orientation: landscape) {
    display: none;
  }
`;

const Group = styled.div<{ open: boolean; anyOpen: boolean }>`
  /*
	@media ${Breakpoints.Mobile} {
		${(props) =>
    props.anyOpen &&
    !props.open &&
    css`
      height: 0;
      overflow: hidden;
    `} 
	}
*/
`;

const Divider = styled.div`
  height: 1px;
  flex: 0 0 1px;
  background-color: ${coldGrey};
  margin-bottom: 20px;
  @media ${Breakpoints.Mobile} {
    margin-bottom: 10px;
  }
`;
/*
const Space = styled.div`
	flex-grow: 1;
`
*/
const Options = styled.div`
  flex: 1 1 0;
  overflow-y: overlay;
  padding-right: 20px;
  margin: 20px;
  margin-right: 10px;
  scrollbar-gutter: stable;

  @media ${Breakpoints.Mobile} {
    flex: 0 1 0;
    margin: 10px;
    margin-right: 0;
    padding-right: 10px;
    overflow-y: unset;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${lightGrey};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${coldGrey};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${anthariceGrey};
  }
`;

const Footer = styled.div`
  border-top: 1px solid ${coldGrey};
  flex: 0 0 0;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${Breakpoints.Mobile},
    ${Breakpoints.Tablet} and (orientation: portrait) {
    justify-content: flex-end;
    margin-bottom: 20px;
    padding: 20px 10px;
  }
`;

const StreamContainer = styled.div.attrs({ id: "streamcontainer" })`
  flex: 1 1 0;
  background-color: transparent;
  min-height: 300px;
  position: relative;

  @media ${Breakpoints.Mobile} and (orientation: portrait) {
    flex: unset;
    min-height: 234px;
    aspect-ratio: 14.4 / 9;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 1;

  @media ${Breakpoints.Mobile} and (pointer: coarse) and (orientation: landscape) {
    display: none;
  }
`;
const CustomOverlay = styled.div<{ show: boolean }>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);

  @media ${Breakpoints.Mobile} and (pointer: coarse) and (orientation: landscape) {
    display: none;
  }
`;

const OverlayButtons = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 20px;

  @media ${Breakpoints.Mobile} {
    margin: 10px;
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModeButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
const DriveButton = styled.div<{ show: boolean }>`
  position: absolute;
  display: ${(props) => (props.show ? "block" : "none")};
  top: 0;
  left: 0;
  @media ${Breakpoints.Mobile} {
    bottom: 0;
    right: 0;
    top:auto;
    left:auto;
  }
`;
const StopButton = styled.div<{ show: boolean }>`
  position: absolute;
  display: ${(props) => (props.show ? "block" : "none")};
  top: 0;
  left: 0;
`;
const DrivingLoading = styled.div<{ show: boolean }>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  background: #000000;
  transition: opacity 0.3s;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: MMC, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  -webkit-letter-spacing: 0.4em;
  -moz-letter-spacing: 0.4em;
  -ms-letter-spacing: 0.4em;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  background-size: cover;
  background-position: 50% 50%;
  vertical-align: middle;
`;

const LoadingInner = styled.div`
  vertical-align: middle;
  position: relative;
  top: calc(50% - 80px);
  background: url(${mitsubishilogo});
  background-size: auto 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: 50%;
`;
const LoadingBar = styled.div`
  position: relative;
  top: calc(50% - 10px);
  background: url(${loadingbar});
  background-size: 512px 13px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: 50%;
`;

const CameraButtons = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: row;
  gap: 10px;
  @media ${Breakpoints.Mobile} {
    gap: 7px;
  }
`;

const TrayButtons = styled.div<{ show: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  gap: 10px;
  @media ${Breakpoints.Mobile} {
    gap: 7px;
  }
`;

const Tray = styled.div<{ open: boolean }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateX(${(props) => (props.open ? 0 : "-100%")});
  transition: transform 0.5s;
  will-change: transform;
  background-color: ${white};
  max-width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
`;

const TrayViewport = styled.div<{ show: boolean }>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.5s;
  will-change: opacity;
  z-index: ${(props) => (props.show ? 99 : 0)};
  pointer-events: ${(props) => (props.show ? "auto" : "none")};
  overflow-x: overlay;
  grid-area: 1 / 1 / 2 / 2;
  padding: 10px 0;
  margin: 10px 10px 10px 90px;

  @media ${Breakpoints.Mobile} {
    padding: 10px 0;
    margin: 0px 10px 0px 50px;
  }

  scrollbar-gutter: stable;

  /* height */
  ::-webkit-scrollbar {
    height: 6px;
    position: absolute;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${lightGrey};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${coldGrey};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${anthariceGrey};
  }
`;

const TrayContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 110px;
  @media ${Breakpoints.Mobile} {
    height: 68px;
  }
  & > button {
    aspect-ratio: 16 / 9;
    flex: 0 0;
  }
`;

interface IProps {
  intl: IntlShape;
}

const getValidatedOptions = (
  config: Config,
  selectedOptions: { [group: string]: string[] }
) => {
  const allowedOptions: { [group: string]: string[] } = {};
  const includedOptions: { [group: string]: string[] } = {};
  const actuallySelectedOptions: { [group: string]: string[] } = {};
  config.groups.forEach((group) => {
    let selectedIds: string[] | undefined =
      selectedOptions[group.id] || (group.default && [group.default]);
    if (allowedOptions[group.id]) {
      if (selectedIds?.length) {
        selectedIds = selectedIds.filter((id) =>
          allowedOptions[group.id].includes(id)
        );
        if (!selectedIds.length) {
          selectedIds = undefined;
        }
      }
    }
    if (!selectedIds && group.mutuallyExclusive) {
      selectedIds = [allowedOptions[group.id]?.[0] || group.options[0].id];
    }
    actuallySelectedOptions[group.id] = selectedIds || [];
    if (selectedIds) {
      const selected = group.options.find((option) =>
        selectedIds!.includes(option.id)
      );
      if (selected) {
        if (selected.compatibility) {
          for (const groupid of Object.keys(selected.compatibility)) {
            if (allowedOptions[groupid]) {
              allowedOptions[groupid] = allowedOptions[groupid].filter((id) =>
                selected.compatibility?.[groupid].includes(id)
              );
            } else {
              allowedOptions[groupid] = selected.compatibility[groupid];
            }
          }
        }
        if (selected.included) {
          for (const groupid of Object.keys(selected.included)) {
            if (includedOptions[groupid]) {
              includedOptions[groupid] = includedOptions[groupid].filter((id) =>
                selected.included?.[groupid].includes(id)
              );
            } else {
              includedOptions[groupid] = selected.included[groupid];
            }
          }
        }
      }
    }
  });
  Object.keys(includedOptions).forEach((groupid) => {
    if (!actuallySelectedOptions[groupid]) {
      actuallySelectedOptions[groupid] = includedOptions[groupid];
    } else {
      for (const optionid of includedOptions[groupid]) {
        if (!actuallySelectedOptions[groupid].includes(optionid)) {
          actuallySelectedOptions[groupid].push(optionid);
        }
      }
    }
  });
  return {
    allowedOptions,
    includedOptions,
    selectedOptions: actuallySelectedOptions,
  };
};

export const getPrCodes = (
  config: Config,
  selected: { [group: string]: string[] },
  mode?: ZLBoltMode,
  ignoreIncluded?: boolean
) => {
  console.log("original selected options", selected);
  const { allowedOptions, includedOptions, selectedOptions } =
    getValidatedOptions(config, selected);

  console.log("allowed options", allowedOptions);
  console.log("included options", includedOptions);
  console.log("final selected options", selectedOptions);

  let groupedPRCodes = config.groups.reduce((prcodes, group) => {
    if (group.mode && group.mode !== mode) {
      return prcodes;
    }
    const options = group.options
      .filter(
        (option) =>
          !(group.id in allowedOptions) ||
          allowedOptions[group.id].includes(option.id)
      )
      .map((o) => o.id);
    const selectedOptionIds =
      selectedOptions[group.id] !== undefined &&
      selectedOptions[group.id].filter(
       (o) => (options.includes(o) && (!includedOptions[group.id]?.includes(o) || ignoreIncluded === false))
      );
    if (selectedOptionIds) {
      prcodes[group.id] = selectedOptionIds;
    } else if (group.mutuallyExclusive) {
      prcodes[group.id] = [
        group.default && options.includes(group.default)
          ? group.default
          : options[0],
      ];
    }
    return prcodes;
  }, {} as { [group: string]: string[] });

  function updateQRcode(prcodes:any) {   

    let url = window.location.origin + "/?brochure=true&prcode=" + prcodes + "&model=" + config.model + "&year=" + config.year;
    let qrCodeAPI = "https://api.qrserver.com/v1/create-qr-code/?size=170x170&data="+encodeURIComponent(url)
    
    let brochureQR=document.getElementById("brochureQR");
    if (!brochureQR) {      
      let qr = document.createElement("div");
      qr.id="brochureQR"
      document.getElementById("Footer")?.prepend(qr)        
    } 
    if (qrCodeAPI && brochureQR) {       
      let img = "<img src='"+qrCodeAPI+"' />" ;
      brochureQR.innerHTML=img;
    } 
  }
  
 /*  if (mode === "2D") {
    Object.keys(groupedPRCodes).forEach((g) => {
      const l = groupedPRCodes[g].length;
      if (l > 1) {
        groupedPRCodes[g] = [groupedPRCodes[g][l - 1]];
      }
    });
    }
*/

  console.log("prcodes", groupedPRCodes);

  if(groupedPRCodes["drivetrain"]) {
    var replacements = getReplacements(config, groupedPRCodes["drivetrain"][0])?.replacements;
    replacements?.forEach(function(swap){
      groupedPRCodes["trim"][0]=groupedPRCodes["trim"][0].replace(swap.find,swap.replace);     
    });
  }

  const prcodes = [
    "US",
    `${groupedPRCodes["trim"][0]}_${groupedPRCodes["fabric"][0]}`,
  ];  

  const extras = Object.values(groupedPRCodes).flat();
  prcodes.push(...extras);
  if (groupedPRCodes["drivetrain"]) {
    const index = prcodes.indexOf(groupedPRCodes["drivetrain"][0]);
    if (index > -1) { 
      prcodes.splice(index,1);
  
    }
  }
  updateQRcode(prcodes)
  return prcodes;
};

function modelSelect() {
  document.location.href="https://mmnadealer.app-platform.zlthunder.net/?url="+encodeURIComponent(document.location.href)
}

var getReplacements = function(config: Config, id: string){
  if( !config.replaceTrims || config.replaceTrims.length===0 ) return {"replacements":[]} ;

  return config.replaceTrims.find( function(drivetrain){
      return drivetrain.id === id;
  });
};

var isMobile = function() {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

function Layout(props: IProps) {
  const [config, setConfig] = useState<Config | undefined>(undefined);
  const [hovered, setHovered] = useState(false);
  const [doubleDragged, setDoubleDragged] = useState(false);  
  const [isConnected, setConnected] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showThanksMessage, setShowThanksMessage] = useState(false);
  const [fullPrCodes, setFullPrCodes] = useState<string[]>([]);

  const inProgress = useAppSelector<boolean>((store) => store.libzl.inProgress);
  const drivingLoading = useAppSelector<boolean>((store) => store.libzl.drivingLoading);

  function onHover() {
    if (!hovered) {
      setHovered(true)
      var rootElement = document.getElementById('root')
      rootElement?.removeEventListener('mouseover', onHover)
      rootElement?.removeEventListener('touchstart', onHover)
      rootElement?.addEventListener('touchstart', doubleDrag)
    }
    function doubleDrag() {
      setDoubleDragged(true)
      var spinner = document.getElementById('spinner')
      if (spinner) spinner.style.display = "inline-block";      
      var rootElement = document.getElementById('root')
      rootElement?.removeEventListener('touchstart', doubleDrag)
    }
  }  
  if (window.zlurlparams.delay === false || window.zlurlparams.brochure === true) onHover()
  var rootElement = document.getElementById('root')
  rootElement?.addEventListener('mouseover', onHover)
  rootElement?.addEventListener('touchstart', onHover)

  useEffect(() => {
    fetch(
      `/configs/${window.zlurlparams.model}_${window.zlurlparams.year}${window.zlurlparams.config}.json`
    )
      .then((response) => {
        return response.json();
      })
      .then((config: Config) => {
        setConfig(config);
        store.dispatch({type: Action.CONFIG_LOADED, data: config as any});
      });
    if (window.zlurlparams.prcode && window.zlurlparams.downloadpdf === true) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, []);

  useEffect(() => {        
    let streamcontainer = document.getElementById('streamcontainer')
    if (config && streamcontainer) streamcontainer.style.backgroundImage = `url("${config.backgroundImage}")`
    if (config && hovered) {
      console.log("Connecting");
      
      // dirty fix for unchecked sessionStorage access
      try{
        const browserTabID = window.sessionStorage.getItem("BrowserTabID") || crypto.randomUUID();
        const userID = window.localStorage.getItem("UserID") || crypto.randomUUID();
        window.sessionStorage.setItem("BrowserTabID", browserTabID);
        window.localStorage.setItem("UserID", userID);  
        document.cookie = "BrowserTabID="+browserTabID+";SameSite=None;Secure";
        document.cookie = "UserID="+userID+";SameSite=None;Secure";

      } catch(err) {
        console.log("sessionStorage unsupported");
      }

      //console.log ("BrowserTabID: "+browserTabID);
      //console.log ("UserID: "+userID);
      if (window.zlurlparams.brochure === true) brochureForm()
      const connecting = connect(
        config.manufacturer,
        config.model,
        config.year,
        getPrCodes(config, {}, "3D", false)
      );
      connecting.then(() => {
        setConnected(true);
        window.localStorage.setItem("ZLBoltID", window.zlbolt.identifier);   
        document.cookie = "ZLBoltID="+window.zlbolt.identifier+";SameSite=None;Secure";
        console.log ("*** ZLBoltID: "+window.zlbolt.identifier);        
      });
    }
  }, [config,hovered]);

  const currentMode = useAppSelector<ZLBoltMode | undefined>(
    (store) => store.libzl.mode
  );
  const currentEnvironment = useAppSelector<string>(
    (store) => store.libzl.environment
  );
  const currentCamera = useAppSelector<string>((store) => store.libzl.camera);
  const loading = useAppSelector<string>((store) => store.libzl.loading);
  const mode = useAppSelector<string>((store) => store.libzl.mode);

  const [selectedGroup, setGroup] = useState<string | undefined>(
    config?.groups[0].id
  );
  const [storedSelectedOptions, setOptions] = useState<{
    [groupId: string]: string[];
  }>({});

  const [trayMode, setTrayMode] = useState<TrayMode | undefined>();

  useEffect(() => {
    if (config) {
      if (window.sequenceRunning === true) pauseDriveSequence();
      store.dispatch({ type: Action.INPROGRESS, data: true });      
      // if prcode passed in the URL parameter, set config to that, unless overridden by changing the config options
      if (Object.keys(storedSelectedOptions).length > 0) {
        setFullPrCodes(getPrCodes(config, storedSelectedOptions, "3D"));
        setPrCodes(getPrCodes(config, storedSelectedOptions, currentMode, false));
      } else {
        setFullPrCodes(window.zlurlparams.prcode.length!==0 ? window.zlurlparams.prcode.split(",") : getPrCodes(config, storedSelectedOptions,  "3D"));
        setPrCodes(window.zlurlparams.prcode.length!==0 ? window.zlurlparams.prcode.split(",") : getPrCodes(config, storedSelectedOptions, currentMode, false));
      }
    }
  }, [config, storedSelectedOptions, currentMode, isConnected]);

  if (!config) {
    return null;
  }

  const { allowedOptions, includedOptions, selectedOptions } =
    getValidatedOptions(config, storedSelectedOptions);
  const stateName = `state_${config.model}_${config.year}`;

  const changeGroup = (group?: ConfigGroup) => {
    if (!inProgress) {
      if (!group) {
        if (selectedGroup) {
          analyticsMarker(["close category", selectedGroup]);
        }
        setGroup(undefined);
      } else if (group.id !== selectedGroup) {
        analyticsMarker(["open category", group.id]);
        setGroup(group.id);
      }
    }
  };

  const setOption = (group: ConfigGroup, option: ConfigGroupOption) => {
    const state = { ...storedSelectedOptions };

    let analyticsType = "";
    if (group.mutuallyExclusive) {
      if (state[group.id]?.length && state[group.id][0] === option.id) {
        return;
      }
      state[group.id] = [option.id];
      analyticsType = "change option";
    } else {
      if (state[group.id] && state[group.id].includes(option.id)) {
        state[group.id] = state[group.id].filter((o) => o !== option.id);
        analyticsType = "remove accessory";
      } else {
        state[group.id] = [...(state[group.id] || []), option.id];
        analyticsType = "add accessory";
      }
    }

    if (option.remove) {
      for (const group of Object.keys(option.remove)) {
        if (state[group]) {
          state[group] = state[group].filter(
            (code) => !option.remove?.[group].includes(code)
          );
        }
      }
    }

    setOptions(state);

    localStorageSetItem(stateName, JSON.stringify(state));
    analyticsMarker([analyticsType, group.id, getAnalyticsName(option.name)], {
      optionId: option.id,
    });

    if (group.camera && currentEnvironment.toLowerCase() !== "mitsubishi_drivingtemplate") {
      const targetCameraType = getCameraType(group.camera, config);
      if (
        targetCameraType &&
        targetCameraType !== getCameraType(currentCamera, config)
      ) {
        setCamera(group.camera);
      }
    }
  };

  const brochureForm = () => {
    //window.removeEventListener("beforeunload");
    //window.addEventListener("beforeunload", () => window.zlbolt.disconnect())
    try { cloudmine({ type: ["CTA", "PersonalizedBrochure"] }); } catch(e) {}
    setShowForm(true);
    window.brochureFormOpen = true;
   // window.parent.postMessage("height|740", "*");
  };
  
  const done = () => {
    analyticsMarker(["finished"], selectedOptions);

    //cloudmine({type: ["CTA", "Reserve", "BUILD & PRICE"]})
    cloudmine({ type: ["CTA", "BuildAndPrice", "ZL"] });

    if (window.zlurlparams.brochure === true) {
      document.location.href="https://www.mitsubishicars.com/configure-your-mitsubishi/";
      return;
    }

    const finalOptions = { ...selectedOptions };
    if (finalOptions.accessorybundles) {
      finalOptions.accessories = [];

      finalOptions.accessorybundles.forEach(function (bundle) {
        config.groups.forEach((group) => {
          if (group.id === "accessorybundles") {
            let options = group.options;
            options.forEach(function (option) {
              if (option.id === bundle) {
                finalOptions.accessories.push(option.code as string);
              }
            });
          }
        });
      });

      delete finalOptions.accessorybundles;
    }
    if (finalOptions.trim) {
      config.groups.forEach((group) => {
        if (group.id === "trim") {
          let options = group.options;
          options.forEach(function (option) {
            if (option.id === finalOptions.trim[0]) {
              finalOptions.trimLine = [];
              finalOptions.trimLine.push(option.trimline_code as string);
              finalOptions.trimCode = [];
              finalOptions.trimCode.push(option.trim_code as string);
              finalOptions.vehicle = [];
              finalOptions.vehicle.push(config.buildAndPriceCode as string);
            }
          });
        }
      });
    }
    postMessage("zl_done", {
      finalOptions,
      ctaName: formatMessage(props.intl, messages.done) as string,
    });
  };

  const changeToMode: ZLBoltMode = currentMode === "2D" ? "3D" : "2D";

  const changeEnv = formatMessage(
    props.intl,
    messages.changeenvironment
  ) as string;
  const changeMode = formatMessage(props.intl, messages.changemode, {
    toMode: changeToMode,
  }) as string;
  const viewExterior = formatMessage(
    props.intl,
    messages.viewexterior
  ) as string;
  const viewInterior = formatMessage(
    props.intl,
    messages.viewinterior
  ) as string;
  const driveSequence = formatMessage(
    props.intl,
    messages.drivesequence
  ) as string;
  const stopSequence = formatMessage(
    props.intl,
    messages.stopsequence
  ) as string;

  const changeTrayMode = (mode: TrayMode): void => {
    if (trayMode === mode) {
      setTrayMode(undefined);
    } else {
      setTrayMode(mode);
    }
  };

  const showThankyouMessage = () => {
    setShowForm(false);
    setShowThanksMessage(true);
  };

  return (
    <Root>
      {showForm && !showThanksMessage ? (
        <Form
          setVisible={setShowForm}
          showThankyouMessage={showThankyouMessage}
          visible={showForm}
          prcodes={fullPrCodes}
        />
      ) : (
        ""
      )}
      {!showForm && showThanksMessage ? (
        <ThankyouMessage setVisible={() => setShowThanksMessage(false)} />
      ) : (
        ""
      )}
      {showMessage ? <Message /> : ""}
      <MainConfig id="MainConfig">
        <StreamContainer>
          {isConnected ? (
            <React.Fragment>
              <Overlay>
                {inProgress ? (
                  <CustomOverlay show={drivingLoading === false}>
                    <Loading />
                  </CustomOverlay>
                ) : (
                  ""
                )}
                {drivingLoading ? (
                  <DrivingLoading show={drivingLoading === true}>
                    <LoadingInner />
                    <LoadingBar />
                  </DrivingLoading>
                ) : (
                  <>
                    <Tray id="Tray" open={trayMode !== undefined && window.sequenceRunning !== true}>
                      <TrayViewport show={trayMode === TrayMode.Environments}>
                        <TrayContent>
                          {Environments.map((environment) => {
                            const icon = window.zlurlparams.userenderchain
                              ? getRenderChainUrl(
                                  selectedOptions,
                                  environment.id,
                                  ExteriorCamera
                                )
                              : `${environment.icon}_${selectedOptions.color[0]}`;
                            return (
                              <IconOption
                                key={environment.id}
                                id={environment.id}
                                name={getI18nString(environment.name)}
                                icon={icon}
                                fallbackIcon={environment.icon}
                                selected={environment.id === currentEnvironment}
                                onClick={() => setEnvironment(environment.id)}
                                inProgress={inProgress}
                              />
                            );
                          })}
                        </TrayContent>
                      </TrayViewport>
                      {config.beautyShots?.length && (
                        <TrayViewport show={trayMode === TrayMode.BeautyShots}>
                          <TrayContent>
                            {config.beautyShots.map((camera) => {
                              const icon = window.zlurlparams.userenderchain
                                ? getRenderChainUrl(
                                    selectedOptions,
                                    currentEnvironment,
                                    camera.id
                                  )
                                : camera.icon;
                              return (
                                <IconOption
                                  key={camera.id}
                                  id={camera.id}
                                  name={getI18nString(camera.name)}
                                  icon={icon}
                                  selected={camera.id === currentCamera}
                                  onClick={() => setCamera(camera.id)}
                                  inProgress={inProgress}
                                />
                              );
                            })}
                          </TrayContent>
                        </TrayViewport>
                      )}
                    </Tray>
                    <OverlayButtons id="OverlayButtons">
                      <ModeButton>
                        <Button
                          id="stream"
                          label={changeMode}
                          onClick={() => setMode(changeToMode)}
                          small
                        />
                      </ModeButton>
                      <DriveButton
                        id="drivingButton"
                        show={window.enableDriving === true && window.zlurlparams?.cinematic !== false}
                      >
                        <Button
                          id="DriveSequence"
                          label={driveSequence}
                          onClick={() => {
                            startDriveSequence(currentEnvironment);
                            setTrayMode(undefined);
                          }}
                          small
                          squashed
                        />
                      </DriveButton>
                      <StopButton show={window.sequenceRunning === true}>
                        <Button
                          id="StopSequence"
                          label={stopSequence}
                          onClick={() => stopDriveSequence()}
                          small
                        />
                      </StopButton>
                      <CameraButtons show={window.sequenceRunning !== true}>
                        <ToggleButton
                          id="ExteriorCamera"
                          icon="exteriorcamera"
                          name={viewExterior}
                          onClick={() => setCamera(ExteriorCamera)}
                          selected={currentCamera === ExteriorCamera}
                        />
                        <ToggleButton
                          id="InteriorCamera"
                          icon="interiorcamera"
                          name={viewInterior}
                          onClick={() => setCamera(InteriorCamera + "_01")}
                          selected={
                            currentCamera === InteriorCamera ||
                            currentCamera.startsWith(`${InteriorCamera}_`)
                          }
                        />
                      </CameraButtons>
                      <TrayButtons id="traybuttons" show={window.sequenceRunning !== true}>
                        <TrayButton
                          id="environments"
                          icon="environments"
                          name={changeEnv}
                          onClick={() => changeTrayMode(TrayMode.Environments)}
                          selected={trayMode === TrayMode.Environments}
                          trayOpen={trayMode !== undefined}
                        />
                        {config.beautyShots?.length && (
                          <TrayButton
                            id="beautyshots"
                            icon="beautyshots"
                            name={changeEnv}
                            onClick={() => changeTrayMode(TrayMode.BeautyShots)}
                            selected={trayMode === TrayMode.BeautyShots}
                            trayOpen={trayMode !== undefined}
                          />
                        )}
                      </TrayButtons>
                    </OverlayButtons>
                  </>
                )}
                {loading && mode !== "3D" && <Loading />}
              </Overlay>
              
            </React.Fragment>
          ) : (
            <LoadingContainer>
              <Loading id="spinner" />
              
            </LoadingContainer>
          )}
          {(isMobile() && isConnected) || doubleDragged ? "" : <Instructions id="instructions" />}
        </StreamContainer>
        <Details id="Details">
          <Options id="Options">
            {config.groups.map((group) => {
              if (group.mode && currentMode !== group.mode) {
                return null;
              }

              const options = group.options.filter(
                (option) =>
                  !(group.id in allowedOptions) ||
                  allowedOptions[group.id].includes(option.id)
              );
              const defaultOption = group.mutuallyExclusive
                ? (group.default &&
                    options.find((o) => o.id === group.default)) ||
                  options[0]
                : undefined;
              const selectedOptionIds = [
                ...(selectedOptions[group.id]
                  ? selectedOptions[group.id]
                  : defaultOption
                  ? [defaultOption.id]
                  : []),
              ].reverse();
              const selectedOption = selectedOptionIds[0]
                ? options.find((o) => o.id === selectedOptionIds[0])
                : undefined;
              const includedOptionIds = (
                includedOptions[group.id] || []
              ).filter(
                (option) =>
                  !(group.id in allowedOptions) ||
                  allowedOptions[group.id].includes(option)
              );

              const closedContent =
                group.mutuallyExclusive && selectedOption
                  ? getI18nString(selectedOption.name)
                  : (formatMessage(props.intl, messages.selecteditems, {
                      selected: selectedOptionIds.length,
                      total: options.length,
                    }) as string);
              return (
                <Group
                  key={group.id}
                  open={selectedGroup === group.id}
                  anyOpen={selectedGroup !== undefined}
                >
                  <Collapsible
                    title={getI18nString(group.name)}
                    inProgress={inProgress}
                    closedContent={
                      <SelectedOption
                        name={closedContent}
                        inProgress={inProgress}
                      />
                    }
                    open={selectedGroup === group.id}
                    onOpen={() => changeGroup(group)}
                    onClose={() => changeGroup(undefined)}
                  >
                    {group.style === "icon" && (
                      <SelectedOption
                        name={closedContent}
                        style={{ marginBottom: 10 }}
                        inProgress={inProgress}
                      />
                    )}
                    <OptionGroup type={group.style}>
                      {options.map((option) => (
                        <Option
                          key={option.id}
                          type={group.style}
                          inProgress={inProgress}
                          id={option.id}
                          name={getI18nString(option.name)}
                          description={
                            option.description &&
                            getI18nString(option.description)
                          }
                          icon={option.icon}
                          selected={selectedOptionIds.includes(option.id)}
                          included={includedOptionIds.includes(option.id)}
                          visual={
                            true
                            //currentMode === "3D" ||
                            //selectedOption?.id === option.id ||
                            //includedOptionIds.includes(option.id)
                          }
                          onClick={
                            includedOptionIds.includes(option.id)
                              ? () => {}
                              : () => setOption(group, option)
                          }
                        />
                      ))}
                    </OptionGroup>
                  </Collapsible>
                  <Divider />
                </Group>
              );
            })}
          </Options>
          <Footer id="Footer">
            <Button
              id="CTA"
              label={formatMessage(props.intl, messages.done) as string}
              onClick={done}
            />
            <CustomButton id="brochure" onClick={() => brochureForm()}>
              PERSONALIZED BROCHURE
            </CustomButton>
            <CustomButton id="modelselect" onClick={() => modelSelect()}>
              CHANGE MODEL
            </CustomButton>
          </Footer>
        </Details>
      </MainConfig>
    </Root>
  );
}
export default injectIntl(Layout);

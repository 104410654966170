import styled, { css } from "styled-components";
import CheckBox from "./Checkbox";
import {
  textColour,
  primaryFont,
  hoverRed,
  lightGrey,
  Breakpoints,
} from "./Constants";
import { ReactComponent as CheckboxIcon } from "./assets/Checkbox.svg";

const Container = styled.button<{ selected: boolean; inProgress: boolean }>`
  appearance: none;
  outline: none;
  text-align: left;
  width: 100%;
  position: relative;
  background-color: ${lightGrey};
  cursor: ${(props) => (props.inProgress ? "not-allowed" : "pointer")};
  transition: opacity 0.1s;
  &:hover {
    opacity: 1;
  }

  ${(props) =>
    props.selected &&
    css`
      &::before {
        content: "";
        position: absolute;
        height: 2px;
        width: 100%;
        background-color: ${hoverRed};
      }
    `}
`;

const Content = styled.div`
  padding: 15px 20px 10px 20px;
  @media ${Breakpoints.Mobile} {
    padding: 10px 20px;
  }
`;

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

const Name = styled.div<{ inProgress: boolean }>`
  color: ${(props) => (props.inProgress ? "#ccc" : textColour)};
  font-family: ${primaryFont};
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  @media ${Breakpoints.Mobile} {
    font-size: 21px;
  }

  flex: 1 1 0;
  justify-content: space-between;
  align-items: flex-start;
`;

const Description = styled.div<{ inProgress: boolean }>`
  color: ${(props) => (props.inProgress ? "#ccc" : textColour)};
  font-family: ${primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;

  white-space: wrap;
`;

interface IProps {
  id: string;
  name: string;
  description?: string;
  included: boolean;
  selected: boolean;
  visual: boolean;
  inProgress: boolean;
  onClick: () => void;
}

export default function TextOption(props: IProps) {
  return (
    <Container
      selected={props.selected && props.visual}
      aria-label={props.name}
      data-automationid={props.id}
      onClick={props.onClick}
      disabled={props.inProgress ? true : props.included}
      inProgress={props.inProgress}
    >
      <Content>
        <Bar>
          <Name inProgress={props.inProgress}>{props.name}</Name>
          <CheckBox selected={props.selected} included={props.included}>
            <CheckboxIcon />
          </CheckBox>
        </Bar>
        {props.description && (
          <Description inProgress={props.inProgress}>
            {props.description}
          </Description>
        )}
      </Content>
    </Container>
  );
}

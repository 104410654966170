import styled from 'styled-components';
import { black, Breakpoints, red, white } from './Constants';
import { getIcon } from './Icons';

const Container = styled.button<{selected: boolean}>`

	& path {
		stroke: ${white};
	}
	backdrop-filter: blur(0.25rem);
	background-color: ${props => props.selected ? black : 'rgba(0, 0, 0, 0.5)'};
	&:hover, &:focus {
		background-color: ${black}
	}
	&::after {
		content: " ";
		position: absolute;
		background-color: ${red};
		display: ${props => props.selected ? 'block' : 'none'};
		width: 100%;
		height: 2px;
		bottom: 0;
	}

	position: relative;
	appearance: none;
	outline: none;

	width: 50px;
	height: 50px;

	@media ${Breakpoints.Mobile} {
		width: 30px;
		height: 30px;
	}

	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: auto;
`;


interface IProps {
	id: string
	icon: string
	name: string
	selected: boolean
	onClick: () => void
}

export default function ToggleButton(props: IProps) {
	return (
		<Container selected={props.selected} aria-label={props.name} data-automationid={props.id} onClick={props.onClick}>
			{getIcon(props.icon)}
		</Container>
	)
}

import { AnyAction, configureStore } from "@reduxjs/toolkit";
import type { Config } from "./helpers";
import { Reducer } from "react";

export enum Action {
  CHANGE_MODE = "CHANGE_MODE",
  CHANGE_ENVIRONMENT = "CHANGE_ENVIRONMENT",
  CHANGE_CAMERA = "CHANGE_CAMERA",
  CAMERA_MOVED = "CAMERA_MOVED",
  LOADING = "LOADING",
  STARTED = "STARTED",
  UPGRADED = "UPGRADED",
  INPROGRESS = "INPROGRESS",
  DRIVINGLOADING = "DRIVINGLOADING",
  CONFIG_LOADED = "CONFIG_LOADED",
}

export interface ILibZLStore {
  mode?: "2D" | "3D";
  environment?: string;
  camera?: string;
  moved: boolean;
  loading: boolean;
  started: boolean;
  upgraded: boolean;
  inProgress: boolean;
  drivingLoading: false;
}

const initialStore: ILibZLStore = {
  moved: false,
  loading: false,
  started: false,
  upgraded: false,
  inProgress: false,
  drivingLoading: false
};

const LibZLReducer = (state = initialStore, action: AnyAction) => {
  switch (action.type) {
    case Action.CHANGE_MODE:
      return {
        ...state,
        mode: action.data,
      };
    case Action.CHANGE_ENVIRONMENT:
      return {
        ...state,
        environment: action.data,
      };
    case Action.CHANGE_CAMERA:
      return {
        ...state,
        camera: action.data,
      };
    case Action.CAMERA_MOVED:
      return {
        ...state,
        moved: action.data,
      };
    case Action.LOADING:
      return {
        ...state,
        loading: action.data,
      };
    case Action.STARTED:
      return {
        ...state,
        started: action.data,
      };
    case Action.UPGRADED:
      return {
        ...state,
        upgraded: action.data,
      };
      case Action.INPROGRESS:
        return {
          ...state,
          inProgress: action.data,
        };
      case Action.DRIVINGLOADING:
        return {
          ...state,
          drivingLoading: action.data,
        };
  }
  return state;
};

const ConfigReducer:Reducer<Config | undefined, AnyAction> = (state = null as any as Config, action: AnyAction) => {
  switch(action.type) {
    case Action.CONFIG_LOADED:
        return {
          ...action.data,
        };
  }
  return state;
}

const store = configureStore({
  reducer: {
    libzl: LibZLReducer,
    config: ConfigReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;

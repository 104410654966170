declare global {
	interface Window {
	  zlbolt: any;
	  pdfUrl: any;
	}
  }
  
  const maxRequests = 50;
  var pdfCreationStarted = false;
  
  export const startPdfCreation = (name: any, prcodes: string[]) => {
	console.log(`start PDF download, name=${name} prcodes=${prcodes}`);
	pdfCreationStarted = true;	
	createPdf(name, prcodes);
  };
  
  export const attemptDownload = (setButtonText: any, setVisible: any, setPdfCreateStarted: any, micrositeRequested: boolean = false) => {
	if (!pdfCreationStarted) return;
  
	if (window.pdfUrl) {
	  const a = document.createElement("a");
	  a.style.display = "none";
	  a.href = window.pdfUrl;
	  document.body.appendChild(a);
	  a.click();
	  setButtonText("Request");
	  if (!micrositeRequested) { window.brochureFormOpen = false; setVisible(false); }
	  setPdfCreateStarted(false);
	  window.pdfUrl=false;
	} else {
	  setTimeout(() => attemptDownload(setButtonText, setVisible, setPdfCreateStarted, micrositeRequested), 1000);
	}
  };
  
  export const createPdf = async (firstName: string, prcodes: string[], retryCount : number = 0) => {
	console.log("running createPdf");
  
	// Prevents multiple button presses - can only be called on initial button press or a retry
	// if (!retryCount) return;
  
	if (window.zlbolt) {
	  window.zlbolt.cloudmine.event({
		type: ["CTA", "PDFRequest"],
	  });
	}
	let token = window.zlbolt.options.token;
	token = token.length ? token : undefined;
	await fetch(`https://pdfgenerator-live.mitsubishi.zlthunder.net/generate`, {
	  method: "POST",
	  body: JSON.stringify({
		contentOptions: {
			prcodes: prcodes.join(","),
			name: firstName,
			model: window.zlurlparams.model,
			year: window.zlurlparams.year,
			...(token ? {token} : {}),
			...(window.zlurlparams.env ? {env: window.zlurlparams.env} : {}),
			...(window.zlurlparams.region ? {region: window.zlurlparams.region} : {}),
		},
		browserOptions: {
		  defaultViewport: {
			width: 595,
			height: 844,
			isLandscape: false,
		  },
		},
		renderOptions: {
		  format: null,
		  width: 595,
		  height: 844,
		  landscape: false,
		  customFilename: "MitsubishiOutlanderBrochure-%%datetime%%.pdf",
		},
	  }),
	  headers: {
		"Content-Type": "application/json",
	  },
	})
	  .then((response) => {
		if (response.status === 200) {
		  console.log("RESPONSE IS 200");
		  //console.log (response.json());
		  return response.json();
		} else {
		  return Promise.reject(
			`Invalid response ${response.status} for download attempt ${
			  retryCount + 1
			}`
		  );
		}
	  })
	  .then((data) => {
		const url = data.outputFile;
		if (!url?.endsWith(".pdf"))
		  return Promise.reject(`Invalid outputFile ${data?.outputFile}`);
		window.pdfUrl = url;
		console.log(url);
		Promise.resolve();
	  })
	  .catch((e) => {
		console.log(`PDF: ${e} `, retryCount);
		if (retryCount < maxRequests) return createPdf(firstName, prcodes, retryCount + 1);
		console.log("PDF: Exceeded max number of retries");
	  });
  };
  
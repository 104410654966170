import { createGlobalStyle } from "styled-components";


export const Global = createGlobalStyle`
	button {
		appearance: none;
		background: none;
		border: none;
		outline: none;
		color: inherit;
		padding: 0px;

		:not(:disabled) {
			cursor: pointer;
		}

		@media only print {
			display: none !important;
		}
		
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		-webkit-tap-highlight-color: transparent;
	}
`;
import React, { Component } from 'react';
import styled from 'styled-components';

export const ContentRootId = "zl-content-root";
export const ModalRootId = "zl-modal-root";
export const TooltipRootId = "zl-tooltip-root";

declare module "react" {
	interface HTMLAttributes<T> extends DOMAttributes<T> {
		inert?: any;
	}
}

const Root = styled.div<{ fullscreen: boolean }>`
	width: 100%;
	height: 100%;	
	margin:auto;
	max-width: ${(props) => (props.fullscreen ? "100%" : "1700px")};
	${props => props.theme.pageRoot}
`;

interface IState {
	hasModal: boolean
}

export class PageRoot extends Component<any, IState> {

	private modalRoot: React.RefObject<HTMLDivElement>;
	private modalObserver: any;

	constructor(props: any) {
		super(props);
		this.state = {
			hasModal: false
		}
		this.modalRoot = React.createRef();

		if (typeof MutationObserver !== 'undefined') {
			this.modalObserver = new MutationObserver(this.onModalChange);
		}
		else {
			console.error("MutationObserver undefined")
		}
	}

	onModalChange = () => {
		this.setState({ hasModal: (this.modalRoot.current && this.modalRoot.current.childElementCount > 0) || false });
	}

	componentDidMount() {
		if (this.modalObserver) {
			this.modalObserver.observe(this.modalRoot.current, { childList: true });
		}
	}

	componentWillUnmount() {
		if (this.modalObserver) {
			this.modalObserver.disconnect();
		}
	}

	render() {
		return (
			<Root fullscreen={window.zlurlparams.fullscreen === true}>
				<div id={ContentRootId} style={{ overflow: this.state.hasModal ? 'hidden' : undefined, height: '100%' }} inert={this.state.hasModal ? 'true' : undefined}>
					{this.props.children}
				</div>
				<div id={ModalRootId} ref={this.modalRoot} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, pointerEvents: 'none', zIndex: 1000 }} />
				<div id={TooltipRootId} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, pointerEvents: 'none', zIndex: 2000 }} />
			</Root>
		);
	}
}

export default PageRoot;

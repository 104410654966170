import styled, { css }  from 'styled-components';
import CheckBox from './Checkbox';
import { textColour, primaryFont, hoverRed, lightGrey } from './Constants';
import { getIcon } from './Icons';
import { ReactComponent as CheckboxIcon } from "./assets/Checkbox.svg";

const Container = styled.button<{ selected: boolean; inProgress: boolean }>`
	appearance: none;
	outline: none;
	text-align: left;
	width: 100%;
	position: relative;

	background-color: ${lightGrey};
	cursor: ${(props) => (props.inProgress ? "not-allowed" : "pointer")};

	transition: opacity 0.1s;
	&:hover {
		opacity: 1;
	}
	
	${props => props.selected && css`
		&::before {
			content: "";
			position: absolute;
			height: 2px;
			width: 100%;
			background-color: ${hoverRed};
		}
	`}
`;


const Bar = styled.div`
	display: flex;
	flex-direction: row;
	margin: 15px 10px;
`;

const Name = styled.div<{ inProgress: boolean }>`
	color: ${(props) => (props.inProgress ? "#ccc" : textColour)};
	font-family: ${primaryFont};
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: left;

	flex: 1 1 0;
	justify-content: space-between;
	align-items: flex-start;
`

const ThumbnailContainer = styled.div<{ inProgress: boolean }>`
	cursor: ${(props) => (props.inProgress ? "not-allowed" : "pointer")};
	pointer-events: ${(props) => (props.inProgress ? "none" : "auto")};
	height: 80px;
	width: 100%;
	> img {
		width: 100%;
		height: 100%;
	}
`

interface IProps {
	id: string
	name: string
	icon?: string
	selected: boolean
	included: boolean
	visual: boolean
	inProgress: boolean
	onClick: () => void
}

export default function TextOption(props: IProps) {
	return (
		<Container 
			selected={props.selected && props.visual} 
			aria-label={props.name} 
			data-automationid={props.id} 
			onClick={props.onClick} 
			disabled={props.inProgress ? true : props.included}
			inProgress={props.inProgress}
		>
			<Bar>
				<Name inProgress={props.inProgress}>{props.name}</Name>
				<CheckBox selected={props.selected} included={props.included}>
					<CheckboxIcon/>
				</CheckBox>
			</Bar>
			<ThumbnailContainer inProgress={props.inProgress}>
				{getIcon(props.icon as string, props.name)}
			</ThumbnailContainer>
		</Container>
	)
}